<template>
  <div>
    <transition name="fade">
      <div @touchend="end" @touchstart="start" @touchmove="move" class="swiper">
        <div @click="chooseItem(item, index)" :style="config5[index]" v-for="(item, index) in imgs" :key="item.cover">
          <img :src="item.img" style="width: 100%; height: 100%" />
        </div>
      </div>
    </transition>
    <!-- <h1 @click="prev">上一个</h1>
    <h1 @click="next">下一个</h1> -->
    <!-- <h1>当前：{{ centerInfo.id }}</h1> -->
  </div>
</template>
1 8 7 0 2 3 4 5 6
<script>
export default {
  name: 'zt',
  data() {
    return {
      jiluindex: '3',
      loading: true,
      currentIndex: 3, //当前中间imgs数组中index
      centerInfo: '', // 当前中间信息
      startX: '',
      endX: '',
      imgs: [
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(1).png',
          value: 0
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(8).png',
          value: 1
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(7).png',
          value: 2
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices.png',
          value: 3
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(2).png',
          value: 4
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(3).png',
          value: 5
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(4).png',
          value: 6
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(5).png',
          value: 7
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(6).png',
          value: 8
        }
      ],
      previous: 0,
      config5: [
        {
          id: '-A',
          position: 'absolute',
          width: '30%',
          maxWidth: '188px',
          height: '140px',
          top: '17.4%',
          left: '12%',
          opacity: 0,
          zIndex: 0,
          transition: '.4s'
        },
        {
          id: 'A',
          position: 'absolute',
          width: '30%',
          maxWidth: '188px',
          height: '140px',
          top: '17.4%',
          left: '12%',
          opacity: 1,
          zIndex: 1,
          transition: '.4s'
        },
        {
          id: 'B',
          position: 'absolute',
          width: '39%',
          maxWidth: '244.4px',
          height: '182px',
          top: '7%',
          left: '23%',
          opacity: 1,
          zIndex: 2,
          transition: '.4s'
        },
        {
          id: 'center',
          position: 'absolute',
          width: '45%',
          maxWidth: '282px',
          height: '210px',
          top: '0px',
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: 1,
          zIndex: 4,
          transition: '.4s'
        },
        {
          id: 'D',
          position: 'absolute',
          width: '39%',
          maxWidth: '244.4px',
          height: '182px',
          top: '7%',
          left: '54.8%',
          opacity: 1,
          zIndex: 2,
          transition: '.4s'
        },
        {
          id: 'E',
          position: 'absolute',
          width: '30%',
          maxWidth: '188px',
          height: '140px',
          top: '17.4%',
          left: '71%',
          opacity: 1,
          zIndex: 1,
          transition: '.4s'
        },
        {
          id: 'E+',
          position: 'absolute',
          width: '30%',
          maxWidth: '188px',
          height: '140px',
          top: '17.4%',
          left: '71%',
          opacity: 0,
          zIndex: 0,
          transition: '.4s'
        }
      ]
    }
  },
  methods: {
    // 获取数据
    async getData() {
      this.$nextTick(() => {
        this.loading = false
      })
    },
    // 滑动上一个
    prev(index) {
      // this.imgs.unshift(this.imgs.pop());
      this.config5.push(this.config5.shift())
      this.currentIndex = this.currentIndex - 1
      if (this.currentIndex < 0) {
        this.currentIndex = this.imgs.length - 1
      }
      this.centerCard()
      this.centerIndex('prev')
    },
    // 滑动下一个
    next() {
      // this.imgs.push(this.imgs.shift());
      this.config5.unshift(this.config5.pop())
      this.currentIndex = this.currentIndex + 1
      if (this.currentIndex > this.imgs.length - 1) {
        this.currentIndex = 0
      }
      this.centerCard()
      this.centerIndex('next')
      // console.log(this.currentIndex);
    },
    // 开始移动端滑动屏幕
    start(event) {
      this.startX = event.changedTouches[0].clientX
      this.startY = event.changedTouches[0].clientY
    },
    // 连续滑动
    move(event) {
      this.endY = event.changedTouches[0].clientY
      this.endX = event.changedTouches[0].clientX
      this.stopDefault(event)
      // 如果是滑动，注解（223行到231行）这段。如果是连续滑动，放开（223行到231行）注解
      this.interval = this.endX - this.startX
      if (this.interval > 40) {
        this.startX = this.endX
        this.prev()
      }
      if (this.interval < -40) {
        this.startX = this.endX
        this.next()
      }
    },
    // 滑动
    end(event) {
      // 如果是滑动，放开（236行到238行）的注解。如果是连续滑动，注解（236行到238行）
      // this.endY = event.changedTouches[0].clientY;
      // this.endX = event.changedTouches[0].clientX;
      // this.formatSwiper();
    },
    formatSwiper() {
      if (this.startX > this.endX) {
        console.log('左边滑动')
        if (this.startX > this.endX + 40) {
          this.next()
        }
      } else {
        console.log('右边滑动')
        if (this.endX > this.startX + 40) {
          this.prev()
        }
      }
    },
    // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
    stopDefault(event) {
      let differenceY = this.endY - this.startY
      let differenceX = this.endX - this.startX
      if (Math.abs(differenceX) > Math.abs(differenceY)) {
        event.preventDefault()
      }
    },
    // 当前imgs在位置上的index（并非img数组的index）
    centerIndex(val) {
      if (val == 'prev') {
        for (let val of this.imgs) {
          if (val.index == this.imgs.length - 1) {
            val.index = 0
          } else {
            val.index = val.index + 1
          }
        }
      } else {
        for (let val of this.imgs) {
          if (val.index == 0) {
            val.index = this.imgs.length - 1
          } else {
            val.index = val.index - 1
          }
        }
      }
    },
    // 点击
    chooseItem(item, index) {
      let cycles = item.value

      // if (item.value < 3) {
      //   console.log(cycles, 'cycles')
      //   for (let i = 0; i <= 3 - cycles; i++) {
      //     console.log(item.value, '123456')
      //     this.prev()
      //   }
      // } else if (item.value >= 3) {
        if (item.value - this.jiluindex < 0) {
          if(Math.abs(item.value - this.jiluindex)!=7 ){
            for (let i = 0; i <= Math.abs(item.value - this.jiluindex); i++) {
              // console.log(item.value, '123456')
              this.prev()
            }
          }
          this.next()
        } else {
          for (let i = 0; i < Math.abs(item.value - this.jiluindex); i++) {
            this.next()
          }
        }

        // if()
        this.jiluindex = item.value
      // }
    },
    // 计算中间卡片信息
    centerCard() {
      this.centerInfo = this.imgs[this.currentIndex]
      this.$emit('centerInfo', this.centerInfo)
      // this.$emit('centerInfo', this.centerInfo);
      // console.log(this.imgs[2].id);
    },

    addCardStyle() {
      if (this.imgs.length > 7) {
        let addtime = this.imgs.length - 7
        for (let i = 0; i < addtime; i++) {
          this.config5.push({
            id: 'center',
            position: 'absolute',
            width: '45%',
            maxWidth: '282px',
            height: '100%',
            top: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
            opacity: 0,
            transition: '.1s'
          })
        }
      }
    }
  },
  created() {
    this.getData()
    this.centerCard() // 获取中间卡片信息
    this.addCardStyle() // 加入样式位置的index
  }
}
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  // border: 1px red solid;
  height: 210px;
  position: relative;
  overflow: hidden;
  div {
    opacity: 0;
  }
}
.preButton {
}
</style>
