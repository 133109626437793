<template>
  <div>
    <div id="china_map" style="width: 600px; height: 400px"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { platform } from '@/api/api.js'
import $ from 'jquery'
require('echarts/map/js/china')
export default {
  data() {
    return {
      compant: {}
    }
  },
  // methods: {},
  created() {
    // this.setEchartOption();
  },
  mounted() {
    this.$nextTick(() => {
      this.getMap()
      // this.setMap()
    })
  },
  methods: {
    getMap() {
      /*platform().then((res) => {
        if (res.status === 'success') {
          this.company = res.data.platform
          // console.log(this.company, 'this.company')
          this.setMap(res.data)
        }
        // console.log(res, 'resssssssss')
      })*/
      let that = this
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/platform',
        dataType: 'json',
        type: 'get',
        async: false,
        data: {},
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          if (res.status === 'success') {
            that.company = res.data.platform
            that.setMap(res.data)
          } else {
          }
        }
      })
    },
    setMap(value) {
      var arr = []
      for (let key in value.region) {
        // arr.push({ name: value.region[key], value: Number(key) })
        arr.push({ name: value.region[key], value: value.platform[key] ? value.platform[key].length : 0, regionId: key })
      }

      // / 初始化echarts实例
      this.chinachart = echarts.init(document.getElementById('china_map'))
      // 进行相关配置
      this.chartOption = {
        tooltip: {
          // 鼠标移到图里面的浮动提示框
          // formatter详细配置： https://echarts.baidu.com/option.html#tooltip.formatter
          formatter(params, ticket, callback) {
            // params.data 就是series配置项中的data数据遍历
            // console.log(params, 'params, ticket, callback')
            let localValue, perf, downloadSpeep, usability, point
            if (params.data) {
              localValue = params.data.value
              perf = params.data.perf
              downloadSpeep = params.data.downloadSpeep
              usability = params.data.usability
              point = params.data.point
            } else {
              // 为了防止没有定义数据的时候报错写的
              localValue = 0
              perf = 0
              downloadSpeep = 0
              usability = 0
              point = 0
            }
            // let htmlStr = `
            //   <div style='font-size:18px;'> ${params.name}</div>
            //   <p style='text-align:left;margin-top:-10px;'>
            //     区域对应数据value：${localValue}<br/>
            //     性能perf：${perf}<br/>
            //     下载速度downloadSpeep：${downloadSpeep}<br/>
            //     可用性usability：${usability}<br/>
            //     监测点数point：${point}<br/>
            //   </p>
            // `
            // return htmlStr
          }
          // backgroundColor:"#ff7f50",//提示标签背景颜色
          // textStyle:{color:"#fff"} //提示标签字体颜色
        },
        // visualMap的详细配置解析：https://echarts.baidu.com/option.html#visualMap
        // visualMap: {
        // 左下角的颜色区域
        // type: 'piecewise', // 定义为分段型 visualMap
        // min: 0,
        // max: 10,
        // itemWidth: 40,
        // bottom: 60,
        // left: 20,
        // pieces: [
        // 自定义『分段式视觉映射组件（visualMapPiecewise）』的每一段的范围，以及每一段的文字，以及每一段的特别的样式
        // {gt: 900, lte: 1000, label: '非常好', color: '#6ad86e'}, // (900, 1000]
        // {gt: 500, lte: 900, label: '正常', color: '#9adcfa'}, // (500, 900]
        // {gt: 310, lte: 500, label: '警告', color: '#ffeb3b'}, // (310, 500]
        // {gt: 200, lte: 300, label: '较差', color: '#ff9800'}, // (200, 300]
        // {gt: 10, lte: 200, label: '非常差', color: 'orangered'}, // (10, 200]
        // {value: 0, label: '无数据', color: '#999'} // [0]
        // ]
        // },
        // geo配置详解： https://echarts.baidu.com/option.html#geo
        geo: {
          // 地理坐标系组件用于地图的绘制
          map: 'china', // 表示中国地图
          // roam: true, // 是否开启鼠标缩放和平移漫游
          zoom: 1.2, // 当前视角的缩放比例（地图的放大比例）
          label: {
            show: true,
            color: '#999'
          },
          itemStyle: {
            // 地图区域的多边形 图形样式。
            borderColor: 'rgba(255, 255, 255, 0.9)',
            areaColor: '#D9D9D9',
            emphasis: {
              // 高亮状态下的多边形和标签样式
              shadowBlur: 20,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        visualMap: {
            min: 0,
            max: 50,
            left: 'left',
            bottom: 40,
            text: ['(家)',''],
            color: ['blue',  '#d9d9d9'],
            calculable: true,
            show: true
        },
        series: [
          {
            name: '', // 浮动框的标题（上面的formatter自定义了提示框数据，所以这里可不写）
            type: 'map',
            mapType: 'china',
            geoIndex: 0,
            roam: true,
            label: {
              show: true
            },
            // 这是需要配置地图上的某个地区的数据，根据后台的返回的数据进行拼接（下面是我定义的假数据）
            // data: arr,
            data: arr
          }
        ]
      }
      // 使用刚指定的配置项和数据显示地图数据
      this.chinachart.setOption(this.chartOption)
      // console.log(this.chinachart, 'this.chinachart')
      this.chinachart.on('click', (params) => {
        let arr = []
        /*platform().then((res) => {
          if (res.status === 'success') {
            this.company = res.data.platform
          }

          // console.log(this.company, 'this.company ')
          for (let key in this.company) {
            arr.push({ name: this.company[key], value: Number(key) })
          }
          // console.log(arr, 'arrr')
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].value == params.data.value) {
              // console.log( 'itemmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm')
              this.$emit('message', arr[i].name)
              return
            }else{
              this.$emit('message', '')
            }
          }
          // arr.map((item) => {
          //   if (item.value == params.data.value) {
          //     console.log(item.name, 'itemmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm')
          //     this.$emit('message', item.name)
          //     // return
          //   }
          // })
          // console.log(params, 'params')
        })*/
        let that = this
        $.ajax({
          url: 'https://outcommon.wonmore.com/trade/platform',
          dataType: 'json',
          type: 'get',
          async: false,
          data: {},
          processData: false, // 使数据不做处理
          contentType: false, // 不要设置Content-Type请求头
          success: function (res) {
            // console.log(res)
            if (res.status === 'success') {
              that.company = res.data.platform

              for (let key in that.company) {
                arr.push({ name: that.company[key], value: Number(key) })
              }
              // console.log('that.company', that.company)
              // console.log('arr', arr)
              // console.log(params.data, 'arrr')
              if (params.data) {
                let _param = [];
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].value == params.data.regionId) {
                    _param = arr[i].name;
                    break
                  }
                }
                that.$emit('message', _param)
              } else {
                that.$emit('message', '')
              }
            }
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#china_map {
  margin: 0 auto;
}
</style>
