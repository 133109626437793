import { createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
// import Home from '../views/Home.vue'
import homePage from '../views/homePage/index.vue'
import Layout from '@/layout'

const routes = [
  {
    path: '/',
    name: 'homePage',
    // redirect: '/homePage',
    component: homePage,
    meta: { title: '央联万贸-全球食品产业生态化公共服务平台', keywords: '食品产业互联网双循环共享平台、食品集采集供平台，全球食品供应链平台，全球食品集成分销平台，冷链食品进出口管理平台，预制菜交易服务平台，预制菜供应链平台', description: '央联万贸平台是食品产业互联网国内外双循环示范项目，集一二三产融合+产业互联网+数字化金融+品牌集群+政策环境于一体，拥有‘一大平台·两大中心·三大贸易服务·五大认证体系’，为上万种冷链食品及国际商品提供进出口报关报检、展示交易、保税仓储、保税加工、跨境电商、物流送达、供应链金融以及进出口、转口、国内贸易等一站式绿色通道综合服务。' }
  },
  //首页
  // {
  //   path: '/homePage/index',
  //   name: 'homePage',
  //   // component: homePage,
  //   redirect: '/',
  //   meta: { title: '央联万贸-全球食品产业生态化公共服务平台', keywords: '食品产业互联网双循环共享平台、食品集采集供平台，全球食品供应链平台，全球食品集成分销平台，冷链食品进出口管理平台、食品安全可追溯系统', description: '央联万贸平台是食品产业互联网国内外双循环示范项目，集一二三产融合+产业互联网+数字化金融+品牌集群+政策环境于一体，拥有‘一大平台·两大中心·三大贸易服务·五大认证体系’，为上万种冷链食品及国际商品提供进出口报关报检、展示交易、保税仓储、保税加工、跨境电商、物流送达、供应链金融以及进出口、转口、国内贸易等一站式绿色通道综合服务。' }
  // },
  // 产业金融
  {
    path: '/aboutUs/finance',
    name: 'Finance',
    component: () => import('@/views/aboutUs/finance'),
    meta: { title: '央联万贸产业金融——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-mobile', keywords: '产业金融、供应链金融', description: '央联万贸平台以大股东身份投资青岛、济南、烟台、潍坊、长岛5家农商银行，拥有6000亿金融资产，同时吸引各大银行入驻，为平台客户提供优越的各项金融服务，打包上市，实现企业可持续发展。' },
  },
  // 关于我们
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs/index'),
    meta: { title: '关于巴龙集团——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '巴龙国际集团、民企500强，产业互联网平台经济领军者', description: '巴龙国际集团创建于1991年，总资产达到50亿多元，年实现销售收入30多亿元，注册资金10亿元，现已发展成为以制衣为龙头，集置业、建设、能源、金融为一体的综合性企业集团，总部位于青岛市香港中路国际金融中心，在北京、上海、济南、杭州、大连、黑龙江、吉林、江西、湖北等地设有几十家分公司。' },
    children: [
      { // 集团介绍
        path: '/aboutUs/company',
        name: 'Company',
        component: () => import('@/views/aboutUs/company'),
        meta: { title: '关于巴龙集团——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '巴龙国际集团、民企500强，产业互联网平台经济领军者', description: '巴龙国际集团创建于1991年，总资产达到50亿多元，年实现销售收入30多亿元，注册资金10亿元，现已发展成为以制衣为龙头，集置业、建设、能源、金融为一体的综合性企业集团，总部位于青岛市香港中路国际金融中心，在北京、上海、济南、杭州、大连、黑龙江、吉林、江西、湖北等地设有几十家分公司。' },
      },
      { // 平台介绍
        path: '/aboutUs/platform',
        name: 'Platform',
        component: () => import('@/views/aboutUs/platform'),
        meta: { title: '央联万贸平台——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '巴龙·央联万贸平台、央联万贸平台介绍、央联万贸平台价值', description: '巴龙·万贸城国际集团有限公司历经8年精心筹划设计，打造国内外双循环示范项目，搭建“三+三+四+五”系统，打造集一二三产融合+产业互联网+数字化金融+品牌集群+政策环境于一体的食品产业互联网双循环5.0共享平台，打通生产、分配、流通、消费各个环节，形成双循环新发展格局，实现绿色高质量发展。' },
      },
      { // 三大服务中心
        path: '/aboutUs/serviceCenter',
        name: 'ServiceCenter',
        component: () => import('@/views/aboutUs/serviceCenter'),
        meta: { title: '央联万贸三大服务中心——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-s-order', keywords: '央联万贸服务中心、海关查验服务中心、一站式集成化中央加工集配中心、国际食品暨跨境商品展示交易中心', description: '海关查验服务中心是国内首家关企深度合作港口集成化服务平台，青岛关区唯一一家多级冷链食品查验中心；一站式集成化中央加工集配中心总库容量96万吨，拥有148个查验月台，未来可实现产能约6000亿，属于世界加工厂；国际食品暨跨境商品展示交易中心是全球规模最大的跨境商品展示交易中心、食品产业全品类展示交易中心、最专业的食品产业博览中心' },
      },
      { // 五大认证体系
        path: '/aboutUs/authentication',
        name: 'Authentication',
        component: () => import('@/views/aboutUs/authentication'),
        meta: { title: '央联万贸五大认证体系系统——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-mobile', keywords: '标准体系、质量体系、价格体系、品牌体系、交易体系', description: '央联万贸建立五大认证体系，确保食品安全、食品质量、食品价格，聚集国内外食品产业品牌，形成青岛、山东及中国北方特质的食品品牌方阵，打造现货、期货、交易、交割、拍卖的食品交易体系。' },
      },
      { // 一站式服务
        path: '/aboutUs/service',
        name: 'Service',
        component: () => import('@/views/aboutUs/service'),
        meta: { title: '央联万贸平台一站式服务——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-mobile', keywords: '一站式服务、一体化服务、全品类、全链条、全温区、全功能、全追溯集成化服务', description: '央联万贸平台针对国际贸易、客户端、国内贸易等不同客户提供一站式服务，打通产地直采、生产加工、仓储、集配、食品溯源等环节，提高食品供应链响应速度。' },
      },
    ]
  },
  {
    path: '/digitalFood',
    name: 'digitalFood',
    component: () => import('@/views/digitalFood/index'),
    meta: { title: '央联万贸数字食品——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '数字食品、数字加工、数字服务、多产融合', description: '央联万贸平台建设数字食品系统、数字加工系统，借助大数据、物联网、AI等技术，提供数字化服务，实现多产融合，实现食品行业高质量发展' },
    children: [
      { // 数字农业
        path: '/digitalFood/digitalAgriculture',
        name: 'digitalAgriculture',
        component: () => import('@/views/digitalFood/digitalAgriculture'),
        meta: { title: '央联万贸数字食品——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '数字食品、数字加工、数字服务、多产融合', description: '央联万贸平台建设数字食品系统、数字加工系统，借助大数据、物联网、AI等技术，提供数字化服务，实现多产融合，实现食品行业高质量发展' }
      },
      { // 数字加工
        path: '/digitalFood/digitalProcessing',
        name: 'digitalProcessing',
        component: () => import('@/views/digitalFood/digitalProcessing'),
        meta: { title: '央联万贸数字食品——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '数字食品、数字加工、数字服务、多产融合', description: '央联万贸平台建设数字食品系统、数字加工系统，借助大数据、物联网、AI等技术，提供数字化服务，实现多产融合，实现食品行业高质量发展' },
      },
      { // 数字服务
        path: '/digitalFood/digitalService',
        name: 'digitalService',
        component: () => import('@/views/digitalFood/digitalService'),
        meta: { title: '央联万贸数字食品——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-s-order', keywords: '数字食品、数字加工、数字服务、多产融合', description: '央联万贸平台建设数字食品系统、数字加工系统，借助大数据、物联网、AI等技术，提供数字化服务，实现多产融合，实现食品行业高质量发展' },
      },
      { // 多产融合
        path: '/digitalFood/productiveIntegration',
        name: 'productiveIntegration',
        component: () => import('@/views/digitalFood/productiveIntegration'),
        meta: { title: '央联万贸数字食品——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-s-order', keywords: '数字食品、数字加工、数字服务、多产融合', description: '央联万贸平台建设数字食品系统、数字加工系统，借助大数据、物联网、AI等技术，提供数字化服务，实现多产融合，实现食品行业高质量发展' },
      },
    ]
  },

  {
    path: '/socialResponsibility',
    name: 'socialResponsibility',
    component: () => import('@/views/socialResponsibility/index'),
    meta: { title: '社会公益——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '央联万贸平台社会公益', description: '央联万贸平台深知企业所担负的社会责任，开展了诸多献爱心捐助活动，并积极的参与各项公益活动' },
    children: [
      { // 社会公益
        path: '/socialResponsibility/publicWelfare',
        name: 'publicWelfares',
        component: () => import('@/views/socialResponsibility/publicWelfare'),
        meta: { title: '社会公益——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '央联万贸平台社会公益', description: '央联万贸平台深知企业所担负的社会责任，开展了诸多献爱心捐助活动，并积极的参与各项公益活动' }
      },
      { // 爱心助农
        path: '/socialResponsibility/helpAgriculture',
        name: 'helpAgriculture',
        component: () => import('@/views/socialResponsibility/helpAgriculture'),
        meta: { title: '爱心助农——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '央联万贸爱心助农、精准扶贫', description: '央联万贸发挥平台优势，助力农产品走出大山、销向全球，未来将持续开展爱心助农活动，为精准扶贫贡献力量' },
      },

    ]
  },
  {
    path: '/cloudService',
    name: 'cloudService',
    component: () => import('@/views/cloudService/index'),
    meta: { title: '央联云服务——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '央联云服务、大数据、云仓、云物流、云商务', description: '央联万贸平台依托大数据、云计算、物联网等体系，打造央联云、大数据、云仓、云物流、云商务等智能生态，提高平台服务的数字化水平，更好地实现产业数字化、数字产业化。' },
    children: [
      { // 大数据服务
        path: '/cloudService/bigData',
        name: 'publicWelfare',
        component: () => import('@/views/cloudService/bigData'),
        meta: { title: '央联云服务——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '央联云服务、大数据、云仓、云物流、云商务', description: '央联万贸平台依托大数据、云计算、物联网等体系，打造央联云、大数据、云仓、云物流、云商务等智能生态，提高平台服务的数字化水平，更好地实现产业数字化、数字产业化。' }
      },
      { // 云仓
        path: '/cloudService/cloudWarehouse',
        name: 'cloudWarehouse',
        component: () => import('@/views/cloudService/cloudWarehouse'),
        meta: { title: '央联云服务——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '央联云服务、大数据、云仓、云物流、云商务', description: '央联万贸平台依托大数据、云计算、物联网等体系，打造央联云、大数据、云仓、云物流、云商务等智能生态，提高平台服务的数字化水平，更好地实现产业数字化、数字产业化。' },
      },
      { // 云物流
        path: '/cloudService/cloudLogistics',
        name: 'cloudLogistics',
        component: () => import('@/views/cloudService/cloudLogistics'),
        meta: { title: '央联云服务——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '央联云服务、大数据、云仓、云物流、云商务', description: '央联万贸平台依托大数据、云计算、物联网等体系，打造央联云、大数据、云仓、云物流、云商务等智能生态，提高平台服务的数字化水平，更好地实现产业数字化、数字产业化。' },
      },
      { // 云商务
        path: '/cloudService/cloudBusiness',
        name: 'cloudBusiness',
        component: () => import('@/views/cloudService/cloudBusiness'),
        meta: { title: '央联云服务——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '央联云服务、大数据、云仓、云物流、云商务', description: '央联万贸平台依托大数据、云计算、物联网等体系，打造央联云、大数据、云仓、云物流、云商务等智能生态，提高平台服务的数字化水平，更好地实现产业数字化、数字产业化。' },
      },
      { // 央联云
        path: '/cloudService/centralUnionCloud',
        name: 'centralUnionCloud',
        component: () => import('@/views/cloudService/centralUnionCloud'),
        meta: { title: '央联云服务——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '央联云服务、大数据、云仓、云物流、云商务', description: '央联万贸平台依托大数据、云计算、物联网等体系，打造央联云、大数据、云仓、云物流、云商务等智能生态，提高平台服务的数字化水平，更好地实现产业数字化、数字产业化。' },
      },
    ]
  },
  // quaniuzixun
  {
    path: '/wordMsg',
    name: 'wordMsg',
    component: () => import('@/views/wordMsg/index'),
    meta: { title: '平台咨讯——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '央联万贸平台、平台功能介绍、平台建设意义、平台应用价值、平台社会效益、平台经济效益', description: '从不同维度浅谈平台的应用价值，让你更全面地了解央联万贸平台' },
    children: [
      { // 平台资讯
        path: '/wordMsg/platform',
        name: 'platform',
        component: () => import('@/views/wordMsg/platform'),
        meta: { title: '平台咨讯——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-money', keywords: '央联万贸平台、平台功能介绍、平台建设意义、平台应用价值、平台社会效益、平台经济效益', description: '从不同维度浅谈平台的应用价值，让你更全面地了解央联万贸平台' }
      },
      { // 行业新闻
        path: '/wordMsg/industryNews',
        name: 'industryNews',
        component: () => import('@/views/wordMsg/industryNews'),
        meta: { title: '行业新闻——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '食品行业新闻、食品集采集供行业热点、冷链食品行业最新动态', description: '汇聚食品集采集供行业最新动态，深耕食品行业赛道，为食品供应商、采购商以及其它领域企业提供全新食品市场洞察' },
      },
      { // 政策规定
        path: '/wordMsg/policyProvisions',
        name: 'policyProvisions',
        component: () => import('@/views/wordMsg/policyProvisions'),
        meta: { title: '政策规定——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-edit-outline', keywords: '食品行业政策、食品最新政策、政策解读', description: '汇总食品以及产业互联网领域相关政策、法律法规、行业标准等内容，解读最新政策，了解国家产业扶持新措施，让大家了解目前面临的政策环境' },
      }
    ]
  },
  //新闻详情
  { // 详情
    path: '/wordMsg/information',
    name: 'information',
    component: () => import('@/views/wordMsg/information'),
    meta: { title: '详情', icon: 'el-icon-edit-outline', keywords: '', description: '' },
  },
  { // 食品溯源
    path: '/foodTraceability/index',
    name: 'foodTraceability',
    component: () => import('@/views/foodTraceability/index'),
    meta: { title: '央联万贸食品溯源——央联万贸-全球食品产业生态化公共服务平台', icon: 'el-icon-mobile', keywords: '央联万贸食品溯源体系、食品安全溯源系统', description: '央联万贸平台搭建食品安全网格化溯源体系，采用大数据管理、信息可视化、一物一码等技术实现食品源头可查、去向可追、责任可究' },
  },
    {
        path: "/404",
        name: "notFound",
        component: () => import('@/components/404')
    },
    {
        path: "/:catchAll(.*)", // 此处需特别注意置于最底部
        redirect: "/"
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  // history: createWebHashHistory(),
  // routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '央联万贸'
  }

  let head = document.getElementsByTagName('head');

  let _keywords = document.querySelector('meta[name="keywords"]')
  if (_keywords) {
    _keywords.content = to.meta.keywords || ''
  } else {
    let meta = document.createElement('meta');
    meta.name = 'keywords'
    meta.content = to.meta.keywords
    head[0].appendChild(meta)
  }

  let _description = document.querySelector('meta[name="description"]')
  if (_description) {
    _description.content = to.meta.description || ''
  } else {
    let meta = document.createElement('meta');
    meta.name = 'description'
    meta.content = to.meta.description
    head[0].appendChild(meta)
  }

  next()
})

export default router
