import { ref } from "vue";

const list = ref([
    "用平台连接食品产业大数据",
    "用科技筑起食品安全保障线",
    "用智慧开启食品产业新生态",
    "央联万贸——领先的食品产业互联网国内外双循环共享平台"
])

let videoIndex = ref(0)
let timer = null;
const CarouselText = function () {
    this.startCarouselText = () => {
        if(timer) return false;
        timer = setInterval(() => {
            if(videoIndex.value == 3){
                videoIndex.value = 0
            }else{
                videoIndex.value ++
            }
        },6000)
    },
    this.finishCarouselText = () => {
        clearInterval(timer)
        timer = null;
    }
}

export {
    list,
    CarouselText,
    videoIndex
}
