import { createStore } from 'vuex'

export default createStore({
  state: {
    isOverflowHidden: true,
    name:[]
  },
  mutations: {
    SET_OVERFLOW_HIDDEN(state, params){
      state.isOverflowHidden = params
    }
  },
  actions: {
  },
  modules: {
  }
})
