<template>
  <div :class="navstyle || isInformation ? 'el-menu-demo1' : 'el-menu-demos'">

      <!-- :text-color="isInformation ? ' #000' : '#fff'" -->
    <el-menu
      :default-active="activeIndex2"
      mode="horizontal"
      text-color="#fff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <img
        style="
          width: 221px;
          height: 47px;
          margin-top: 25px;
          margin-right: 40px;
        "
        :src="
          navstyle || isInformation
            ? 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/logo1.png'
            : 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/logo.png'
        "
        alt=""
      />
      <el-menu-item index="homePage"><a href="https://www.wonmore.com/" @click.prevent="turnNone()">首页</a></el-menu-item>
      <el-sub-menu index="aboutUs">
        <template #title>关于我们</template>
        <el-menu-item index="Company"><a href="https://www.wonmore.com/aboutUs/company" @click.prevent="turnNone()">集团介绍</a></el-menu-item>
        <el-menu-item index="Platform"><a href="https://www.wonmore.com/aboutUs/platform" @click.prevent="turnNone()">平台介绍</a></el-menu-item>
        <el-menu-item index="ServiceCenter"><a href="https://www.wonmore.com/aboutUs/serviceCenter" @click.prevent="turnNone()">港口经济三大中心</a></el-menu-item>
        <el-menu-item index="Authentication"><a href="https://www.wonmore.com/aboutUs/authentication" @click.prevent="turnNone()">五大体系</a></el-menu-item>
        <el-menu-item index="Service"><a href="https://www.wonmore.com/aboutUs/service" @click.prevent="turnNone()">一站式服务</a></el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="wordMsg">
        <template #title>全球资讯</template>
        <el-menu-item index="platform"><a href="https://www.wonmore.com/wordMsg/platform" @click.prevent="turnNone()">平台资讯</a></el-menu-item>
        <el-menu-item index="industryNews"><a href="https://www.wonmore.com/wordMsg/industryNews" @click.prevent="turnNone()">行业新闻</a></el-menu-item>
        <el-menu-item index="policyProvisions"><a href="https://www.wonmore.com/wordMsg/policyProvisions" @click.prevent="turnNone()">政策规定</a></el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="socialResponsibility">
        <template #title>社会责任</template>
        <el-menu-item index="publicWelfares"><a href="https://www.wonmore.com/socialResponsibility/publicWelfare" @click.prevent="turnNone()">社会公益</a></el-menu-item>
        <el-menu-item index="helpAgriculture"><a href="https://www.wonmore.com/socialResponsibility/helpAgriculture" @click.prevent="turnNone()">爱心助农</a></el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="digitalFood">
        <template #title>数字食品</template>
        <el-menu-item index="digitalAgriculture"><a href="https://www.wonmore.com/digitalFood/digitalAgriculture" @click.prevent="turnNone()">数字农业</a></el-menu-item>
        <el-menu-item index="digitalProcessing"><a href="https://www.wonmore.com/digitalFood/digitalAgriculture" @click.prevent="turnNone()">数字加工</a></el-menu-item>
        <el-menu-item index="digitalService"><a href="https://www.wonmore.com/digitalFood/digitalAgriculture" @click.prevent="turnNone()">数字服务</a></el-menu-item>
        <el-menu-item index="productiveIntegration"><a href="https://www.wonmore.com/digitalFood/digitalAgriculture" @click.prevent="turnNone()">多产融合</a></el-menu-item>
      </el-sub-menu>
      <el-menu-item index="foodTraceability"><a href="https://www.wonmore.com/foodTraceability/index" @click.prevent="turnNone()">食品溯源</a></el-menu-item>
      <el-menu-item index="Finance"><a href="https://www.wonmore.com/aboutUs/finance" @click.prevent="turnNone()">产业金融</a></el-menu-item>
      <el-sub-menu index="cloudService">
        <template #title>央联云服务</template>
        <el-menu-item index="publicWelfare"><a href="https://www.wonmore.com/cloudService/bigData" @click.prevent="turnNone()">大数据服务</a></el-menu-item>
        <el-menu-item index="cloudWarehouse"><a href="https://www.wonmore.com/cloudService/cloudWarehouse" @click.prevent="turnNone()">云仓</a></el-menu-item>
        <el-menu-item index="cloudLogistics"><a href="https://www.wonmore.com/cloudService/cloudLogistics" @click.prevent="turnNone()">云物流</a></el-menu-item>
        <el-menu-item index="cloudBusiness"><a href="https://www.wonmore.com/cloudService/cloudBusiness" @click.prevent="turnNone()">云商务</a></el-menu-item>
        <el-menu-item index="centralUnionCloud"><a href="https://www.wonmore.com/cloudService/centralUnionCloud" @click.prevent="turnNone()">央联云</a></el-menu-item>
      </el-sub-menu>
      <!-- <el-menu-item>
        <span class="mallBtn" @click="goMall">
          <span class="fa fa-shopping-cart"></span>&nbsp; 商城入口
        </span>
        <span class="mallBtn1">商城入口</span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex2: '1',
      isInformation: false
    }
  },
  props: ['navstyle'],
  mounted() {
    this.judgeIsInformation()
  },
  beforeUpdate() {
    this.judgeIsInformation()
  },
  methods: {
    turnNone(){},
    judgeIsInformation () {
      let _url = window.location.href
      if (_url.indexOf("wordMsg/information") > -1) {
        this.isInformation = true
      } else {
        this.isInformation = false
      }
    },
    handleSelect(key, keyPath) {
      this.$router.push({
        name: key
      })
    },
    // goMall () {
    //   window.open("http://mall.wonmore.com/");
    // }
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      // console.log('fromfrom', from, to)

      // vm.second = from.meta.title
      // vm.routerName = from.name;
      // console.log(vm.routerName);
    })
  }
}
</script>

<style scoped>
/deep/ .el-menu--horizontal > .el-menu-item,
/deep/ .el-menu--horizontal > .el-sub-menu .el-sub-menu__title{
  padding: 0 25px;
}
/deep/ .el-menu--horizontal > .el-menu-item {
  height: 96px;
  line-height: 96px;
}
/deep/ .el-menu--horizontal {
  border-bottom: none;
  background: transparent !important;
  justify-content: center;
}
/deep/ .el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  height: 96px;
  line-height: 96px;
}
/deep/ .el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
/deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
/deep/ .el-menu--popup .el-menu-item:not(.is-disabled):focus,
.el-menu--popup .el-menu-item:not(.is-disabled) {
  background: #c5a074 !important;
}
/deep/ .el-menu--popup .el-menu-item:not(.is-disabled):focus,
.el-menu--popup .el-menu-item:not(.is-disabled):hover {
  background: rgba(165,137,99, 1) !important;
}
/deep/ .el-menu--horizontal .el-menu .el-menu-item.is-active, .el-menu--horizontal .el-menu .el-sub-menu.is-active>.el-sub-menu__title{
    background: rgba(165,137,99, 1) !important;
    color:#fff !important
}
/deep/ .el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title{
  /* border-bottom: 2px solid rgba(165,137,99, 1) !important; */
    /* color: var(--el-menu-active-color); */
    border-bottom: none;
    color: rgba(165,137,99, 1) !important;
}
/deep/ .el-popper.is-light {
  background: #c5a074 !important;
}
/deep/ .el-menu--horizontal>.el-menu-item.is-active{
      border-bottom: none !important;
        color: rgba(165,137,99, 1) !important;
}
/* /deep/ .el-popper .el-menu--horizontal  */
</style>
<style lang="scss" scoped>
.el-menu-demos {
  height: 96px;
  position: absolute;
  // padding-left: 360px;
  top: 0;
  z-index: 999999999;
  background: no-repeat rgba(0, 0, 0, 0);
  background-position: 360px 20px;
  width: 100%;
  font-size: 16px;
  color: #fff;
  // ::v-deep .el-popper {
  //   background: #c5a074 !important;
  // }
}
.el-menu-demo1 {
  height: 96px;
  position: fixed;
  // padding-left: 360px;
  top: 0;
  z-index: 999999999;
  // background: no-repeat rgba(0, 0, 0, 0.1);
  background-position: 360px 20px;
  width: 100%;
  font-size: 16px;
  background-color: #fff !important;
  color: #000 !important;
  ::v-deep .el-menu-item {
    color: #000;
  }
  ::v-deep .el-sub-menu__title {
    color: #000 !important;
  }
  // ::v-deep .el-menu--popup {
  //   background: #c5a074 !important;
  // }
}
.mallBtn{
  color: #fff;
  height: 46px;
  line-height: 46px;
  background: #c5a375;
  padding: 0 15px;
  border-radius: 23px;
  span.fa{ vertical-align: text-top; }
}
@media screen and (min-width: 1220px) {
  .mallBtn{ display: inline-block;}
  .mallBtn1{ display: none;}
}
@media screen and (max-width: 1219px) {
  .mallBtn{ display: none;}
  .mallBtn1{ display: inline-block;}
  .el-menu--horizontal .el-menu .el-sub-menu__title{ background-color: #c5a074 !important; }
}
</style>
<style>
.el-menu--popup {
  padding: 0 !important;
}
.el-popper.is-pure {
  position: fixed !important;
  top: 100px !important;
  z-index: 999999999 !important;
}
.el-popper.is-light{
  border: 0 !important;
  border-radius: 5px;
  overflow: hidden;
}
/*  background: rgba(165,137,99, 1) !important; */
/* .el-popper.is-pure .el-menu-item:hover{
    background: #C5A074 !important;
  } */
</style>
