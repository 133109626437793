<template>
 <!-- @mousewheel="rollScroll($event)" -->
  <div id="app" :style="{'overflow': isOverflowHidden ? 'hidden': 'inherit'}">
    <Nav :navstyle="posi"></Nav>
    <router-view />
    <End></End>
  </div>
</template>
<script>
import Nav from '@/components/Navigation'
import End from '@/components/end'
export default {
  components: {
    Nav,
    End
  },
  data(){
    return{
      posi:false
    }
  },
  computed:{
    isOverflowHidden(){
      if(this.$route.path == "/"){
        return this.$store.state.isOverflowHidden
      }else{
        return false
      }
    }
  },
  mounted() {
    this.scrollInit()
    this.scrollHandle()
  },
  methods: {
    scrollInit() {
			const scrollDiv = document.getElementById("app");
			var that = this;
			var isFirefox=/Firefox/i.test (navigator.userAgent) ; //判断是不是火狐浏览器
			var mwEvent = !isFirefox ? 'mousewheel' : 'DOMMouseScroll'
			scrollDiv.addEventListener(mwEvent, function (e) {
        that.rollScroll(e, mwEvent);
      }, false);
    },
    scrollHandle(){
      let that = this
			window.addEventListener('scroll', function (e) {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 0 && !that.posi) that.posi = true
      }, false);
    },
    rollScroll(e, _type) {
      // console.log(document.documentElement.scrollTop,'yyyyyyyyyyyyyyyyyy')
      var delta = (_type == 'mousewheel') ? (e.wheelDelta || -e.deltaY) : -e.detail
      let _posi = this.posi
      if(delta < 0){
        if (!_posi) this.posi = true
      }else if(document.documentElement.scrollTop == 0){
        if (_posi) this.posi = false
      }
    },
    // getScroll() {
    //   return {
    //     left:
    //       window.pageXOffset ||
    //       document.documentElement.scrollLeft ||
    //       document.body.scrollLeft ||
    //       0,
    //     top:
    //       window.pageYOffset ||
    //       document.documentElement.scrollTop ||
    //       document.body.scrollTop ||
    //       0
    //   }
    // }
  }
}
</script>


<style lang="scss">
html {
  height: 100%;
}
div {
  box-sizing: border-box;
}
body {
  height: 100%;
  margin: 0;
}
ul,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
p,
ul,
li {
  margin: 0;
}
#app {
  min-width: 1200px;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
a{
  text-decoration: none;
  color: inherit;
}
</style>
