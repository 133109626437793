import axios from "axios";

import config from "../config";

import {ElMessage} from 'element-plus'

import router from "../router";

const TOKEN_INVALID = 'token 认证失败，请重新登陆！'

const ENTWORK_ERROR = '网络请求异常，请稍后重试！'

//创建axios实例对象，添加全局配置

const service = axios.create({

    baseURL: config.baseApi,

    timeout: 8000

})

//请求拦截

service.interceptors.request.use((req) => {

    const headers = req.headers

    if (!headers.Authorization) headers.Authorization = 'aurora yan'

    return req

})

service.interceptors.response.use((res) => {

    const {data, status, statusText} = res
    // console.log(res)

    if (status === 200) {

        return data

    } else if (status === 40001) {

        ElMessage.error(TOKEN_INVALID)

        setTimeout(() => {

            router.push('/login')

        }, 1500)

        return Promise.reject(TOKEN_INVALID)

    } else {

        ElMessage.error(statusText || ENTWORK_ERROR)

        return Promise.reject(statusText || ENTWORK_ERROR)

    }

})

/**

 * 请求核心函数

 * @param {*} options 请求配置

 * @returns

 */

const request = (options) => {

    options.method = options.method || 'get'

    if (options.method.toLowerCase() === 'get') {

        options.params = options.data

    }

    // if(config.env === 'prod'){
    //
    //     service.defaults.baseURL = config.baseApi
    //
    // }else{
    //
    //     service.defaults.baseURL = config.mock ? config.mockApi : config.baseApi
    //
    // }

    return service(options)

}

['get', 'post', 'put', 'delete', 'patch'].forEach((item) => {

    request[item] = (url, data, options) => {

        return request({

            url,

            data,

            method: item,

            ...options

        })

    }

})

export default request
