<template>
<div>

</div>
  <div class="end">
    <div class="container">
      <div class="contnet">
        <div class="we">联系我们</div>
        <div class="methods">
          <div class="phone">致电</div>
          <div class="mobile">400-000-0101</div>
          <div class="phone">邮箱：wmc@wonmore.com</div>
          <div class="phone">地址：青岛市香港中路59号国际金融中心</div>
        </div>
      </div>
      <div class="contnet" >
        <div class="we">关于我们</div>
        <div class="methods" >
          <a class="phone" @click.prevent="gomyself(1)" href="https://www.wonmore.com/aboutUs/company">集团介绍</a>
          <a class="phone" @click.prevent="gomyself(2)" href="https://www.wonmore.com/aboutUs/platform">平台介绍</a>
          <!-- <h2 @click="gomyself(3)">发展历程</h2> -->
        </div>
      </div>
      <div class="contnet" >
        <div class="we">友情链接</div>
        <div class="methods" >
          <a class="phone" href="http://www.chinaballon.com/" target="_blank">巴龙国际集团</a>
        </div>
      </div>
      <div class="contnet">
        <div class="we">关注我们</div>
        <div class="follow"></div>
      </div>
      <div class="fei">
        Copyright&nbsp;
        <a style="color: #fff; text-decoration: none;" href="http://www.beian.gov.cn/portal/index.do" target="_blank">鲁ICP备15041398号-9</a>
        © 央联万贸</div>
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    gomyself(value){
      if(value==1){
     this.$router.push({
        name:'Company'
      })
      }else if(value==2){
     this.$router.push({
        name:'Platform',
        query: {isTop:1}
      })
      }

    }
  }
}
</script>
<style lang="scss" sdoped>
.end {
  width: 100%;
  padding: 35px 50px 25px;
  background: #1b3f5b;
  .container{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .contnet {
    &:nth-child(3){width: 180px;}
    width: 284px;
    .we {
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
    }
    .follow{
      width: 106px;
      height: 106px;
      background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/focusUs.jpg") no-repeat;
      background-size: 100% 100%;
      margin-top: 15px;
    }
    .methods {
      .phone {
        display: block;
        cursor: pointer;
        margin: 16px 0;
        height: 24px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
      .mobile {
        height: 32px;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
      }
    }
  }
  .fei{
    width: 100%;
    color: #fff;
    text-align: center;
    padding-top: 20px;
  }
}
</style>
