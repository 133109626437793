<template>
  <div class="homepage" id="homepage" ref="carousel" >
    <div class="ylvideo" :class="[isActived == 1 ? 'active' : isActived == 2 ? 'noActive' : '']" :style="{ height: winHeight + 'px' }">
      <video
        v-show="videoCanPlay"
        muted
        loop="loop"
        :src="moveSrc"
        autoplay="autoplay"
        @canplay="canplay"
      ></video>
      <div class="video-mask">
        <p class="animate__animated" :class="[videoIndex == index ? 'animate__fadeInDown' : 'animate__fadeOutDown']" v-for="item,index in videoList" :key="index">{{item}}</p>
      </div>
      <img v-show="!videoCanPlay" style="width:100vw;height:100vh;" src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/image-url.jpg" alt="">
    </div>
    <div class="downDom">
      <div class="introduce">
        <transition name="el-zoom-in-center">
          <div class="transition-box">
            <img
              :src="jtSrc"
              class="animate__animated animate__slideInLeft"
              :style="{ height: winHeight + 'px' }"
              alt=""
            />
            <div
              class="flotTop animate__animated animate__flipInX"
              v-show="text1"
            >
              <h1 class="title">集团介绍</h1>
              <h1 class="etitle">Group Introduction</h1>
              <p class="msg">
                巴龙国际集团创建于1991年，涵盖金融、国贸、平台经济、冷链、食品、服饰、建设。中国民企500强。 集团管理中心位于青岛国际金融中心。 在北京、上海、天津、济南、青岛、武汉等全国主要枢纽城市设立数十家分公司。<br/>
                巴龙·央联万贸集团总部位于北京CBD中心，创新食品产业互联网双循环共享平台，产业互联网平台经济领军者。<br/>
                巴龙·央联万贸——践行国家使命，推动健康中国
              </p>
            </div>
          </div>
        </transition>
      </div>
      <div class="advantage">
        <div class="fixtwobox">
          <div class="pto animate__animated animate__fadeInUp" v-show="pto2">
            <img class="bigpto" :src="bg18Src" alt="" />
          </div>
          <div
            class="text pto animate__animated animate__flipInX"
            v-show="text2"
          >
            <!--      background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/dualcycle.png')
          no-repeat; -->
            <h1 class="bgcE">
              愿景和使命
              <img
                v-show="text4"
                class="eb animate__animated animate__fadeInUp"
                src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/vision1.png"
                alt=""
              />
            </h1>
            <p>
              制定食品安全标准，搭建食品安全屏障，打造食品安全品牌城市，实现食品安全“四个最严”，确保不发生系统性、区域性食品安全风险。
              运用产业互联网平台模式，解决经济运行过程中的痛点、难点、堵点，促进企业合作、产业融合、城乡协同。
              运用产业互联网平台模式，打造国内国际双循环示范项目，推进构建“双循环”发展格局。
              运用产业互联网平台模式，构建政府推动、平台运营、企业合作、产业融合的新型循环经济发展格局。
            </p>
          </div>
        </div>
        <div class="fixtwobox">
          <div
            class="text text1 pto animate__animated animate__flipInX"
            v-show="text3"
          >
            <div>
              <h1 class="bgcE">
                国际国内双循环
                <img
                  v-show="pto4"
                  class="eb animate__animated animate__fadeInUp"
                  src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/dualcycle.png"
                  alt=""
                />
              </h1>
              <p>
                <span>
                  发挥青岛及山东的比较优势，建设运营港口经济“三大中心”、搭建“三大平台”、“四大服务系统”、“五大体系”，形成集一二三产融合+产业互联网+数字化金融+品牌集群+政策环境于一体的食品产业互联网国内外双循环5.0共享平台。打造新型有计划的市场经济发展格局，促进企业合作、多产融合、城乡协同发展，推动城市间、城乡间、国内外消费、产业、贸易的大流通双循环，实现资源、消费、交易的二次平衡，引领优质消费升级，推动供给侧结构性改革，助力乡村振兴，实现城乡融合绿色高质量发展，促进人民共同富裕。
                </span>
              </p>
            </div>
          </div>
          <div class="pto animate__animated animate__fadeInUp" v-show="pto3">
            <img class="bigpto" :src="bg33Src" alt="" />
          </div>
        </div>
      </div>
      <!-- 三大中心 -->
      <div class="swiper-card">
        <div class="swper_title">
          <h1>平台港口经济“三大中心”</h1>
          <h3>"THREE CENTERS" OF PLATFORM PORT ECONOMY </h3>
        </div>
         <!-- height="432px" -->
        <el-carousel :interval="4000" :autoplay="false" type="card" height="calc(50vw / 83 * 36)">
          <el-carousel-item v-for="item in threeBigcenter" :key="item">
            <div class="medium">
              <img class="medium-img" :src="item.img" alt="" />
              <div class="medium-text">
                <div class="title">{{ item.title }}</div>
                <div class="text line6">{{ item.text }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 三大平台 -->
      <div class="tree-platform">
        <div class="tree-title">
          <h1>三大平台</h1>
          <h3>THREE PLATFORMS</h3>
        </div>
        <div class="tree-pto">
          <div class="pro_text">
            <img :src="bg2Src" alt="" />
            <div class="titles">
              <h1>消费平台</h1>
              <h3>CONSUMER PLATFORM</h3>
            </div>
          </div>
          <div class="pro_text" style="margin: 0 24px">
            <img :src="bg3Src" alt="" />
            <div class="titles">
              <h1>产业平台</h1>
              <h3>INDUSTRY PLATFORM </h3>
            </div>
          </div>
          <div class="pro_text">
            <img :src="bg4Src" alt="" />
            <div class="titles">
              <h1>贸易平台</h1>
              <h3>TRADING PLATFORM</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- 四大服务系统 -->
      <div class="four-service">
        <div class="titls">
          <h1>四大服务系统</h1>
          <h3>FOUR MAJOR SERVICE SYSTEMS</h3>
        </div>
        <div class="four-pto">
          <div class="col1">
            <div class="pto-text pto-text1">
              <!-- <img class="anpto" :src="bg19Src" alt="" /> -->
              <div class="text">
                <h1>互联网服务系统</h1>
                <h3>INTERNET SERVICE SYSTEMS</h3>
              </div>
              <img
                src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/number.png"
                alt=""
              />
              <div class="zzc"></div>
            </div>
            <div class="pto-text pto-text2">
              <!-- <img :src="bg20Src" alt="" /> -->
              <div class="text">
                <h1>供应链服务系统</h1>
                <h3>SUPPLY CHAIN SERVICE SYSTEMS</h3>
              </div>
              <img
                src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/number(1).png"
                alt=""
              />
              <div class="zzc"></div>
            </div>
          </div>
          <div class="col1">
            <div class="pto-text pto-text3">
              <!-- <img :src="bg21Src" alt="" /> -->
              <div class="text">
                <h1>数字金融服务系统</h1>
                <h3>DIGITAL FINANCIAL SERVICE SYSTEMS</h3>
              </div>
              <img
                src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/number(2).png"
                alt=""
              />
              <div class="zzc"></div>
            </div>
            <div class="pto-text pto-text4">
              <!-- <img :src="bg22Src" alt="" /> -->
              <div class="text">
                <h1>品牌集群服务系统</h1>
                <h3>BRAND CLUSTER SERVICE SYSTEMS</h3>
              </div>
              <img
                src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/number(3).png"
                alt=""
              />
              <div class="zzc"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 五大认证系统 -->
      <div class="five-system">
        <div class="titls">
          <h1>五大认证系统</h1>
          <h3>FIVE CERTIFICATION SYSTEMS</h3>
        </div>
        <div class="five-pto">
          <div class="ptos">
            <img class="withbig" :src="bg23Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              全球首家发布冷链国际食品产业团体标准企业，与中国标准化研究院深度合作，共同制定并发布食品安全标准。
            </div>
            <div class="titlet">
              <div><img :src="bzSrc" alt="" /></div>
              <h1>标准体系</h1>
              <h3>STANDARD SYSTEMS</h3>
            </div>
          </div>
          <div class="ptos" style="margin: 0 24px">
            <img class="withbig" :src="bg24Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              制定食品安全标准，搭建食品安全屏障，打造食品安全品牌城市，实现食品安全“四个最严”，确保不发生系统性、区域性食品安全风险。实现全球冷链食品的检验、检测、认证、溯源、原产地评定、境外预检验、食品安全风险评估、食品标签咨询、有害生物管理、环境监测及评价、管理体系认证等服务
            </div>
            <div class="titlet">
              <div><img :src="zlSrc" alt="" /></div>
              <h1>质量体系</h1>
              <h3>QUALITY SYSTEMS</h3>
            </div>
          </div>
          <div class="ptos">
            <img class="withbig" :src="bg25Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              为政府提供决策依据，消除汇率波动对国内农副产品流通产业的影响，提前预警，指导种植养殖，满足中国市场消费者的需求，推动世界经济发展
            </div>
            <div class="titlet">
              <div><img :src="jgSrc" alt="" /></div>
              <h1>价格体系</h1>
              <h3>PRICE SYSTEMS</h3>
            </div>
          </div>
          <div class="ptos" style="margin: 0 24px">
            <img class="withbig" :src="bg26Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              品牌管理中心，品牌发布中心，品牌大数据中心，品牌价值评估中心，品牌研究中心，品牌培训中心提供产品身份证及品牌身份证。充分发挥食品企业品牌效应，为企业赋能增值，助力中国产业品牌化，品牌产业国际化
            </div>
            <div class="titlet">
              <div><img :src="ppSrc" alt="" /></div>
              <h1>品牌体系</h1>
              <h3>BRAND SYSTEMS</h3>
            </div>
          </div>
          <div class="ptos">
            <img class="withbig" :src="bg27Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              搭建线上线下深度融合的O2O交易平台，为国内外贸易商、进口商提供价格发布、产品展示、交易结算服务，实现现货、期货的线上线下交易、交割、拍卖
            </div>
            <div class="titlet">
              <div><img :src="jySrc" alt="" /></div>
              <h1>交易体系</h1>
              <h3>TRADING SYSTEMS</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- 平台科技手段 -->
      <div class="scientific">
        <div class="titls">
          <h1>平台科技手段</h1>
          <h3>Technical means</h3>
        </div>
        <div class="scientific-pto">
          <div class="ptos">
            <div class="titlet">
              <h1>SAAS平台</h1>
              <h3>SAAS PLATFORM</h3>
            </div>
            <img class="withbig" :src="bg34Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              业务数据独立，保障客户数据安全隔离
              真正意义上做到了多租户SAAS系统独立前台、共享后台、共享数据库的系统平台
            </div>
          </div>
          <div class="ptos" style="margin: 0 24px">
            <div class="titlet">
              <h1>区块链技术</h1>
              <h3>BLOCK CHAIN</h3>
            </div>
            <img class="withbig" :src="bg12Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              通过CFCA
              CA证书区块链节点身份鉴权、国密算法支持、SGX安全保护等能力，打造多维度的区块链安全体系。区块链运行模块实现区块链各节点云监控，提供实时监控区块链状态和自动报警的功能。
            </div>
          </div>
          <div class="ptos">
            <div class="titlet">
              <h1>云服务</h1>
              <h3>CLOUD SERVING</h3>
            </div>
            <img class="withbig" :src="bg13Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              实现24小时无人值守监测，随时掌握状态，出现异常第一时间微信、短信发出报警，避免因安全管理不当和不及时处理而对企业造成不必要的损失。
            </div>
          </div>
          <div class="ptos" style="margin: 0 24px">
            <div class="titlet">
              <h1>5G</h1>
              <h3>5G TECHNOLOGY</h3>
            </div>
            <img class="withbig" :src="bg14Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              通过5G新一代信息通信技术深度融合线下平台，为平台产业数字化、网络化、智能化发展提供了新的实现途径。应用涵盖研发设计、生产制造、运营管理及产品服务4个大的工业环节。
            </div>
          </div>
          <div class="ptos">
            <div class="titlet">
              <h1>大数据</h1>
              <h3>BIF DATA</h3>
            </div>
            <img class="withbig" :src="bg15Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext">
              具备高效的数据处理引擎，支撑各种复杂的数据转换和任务调度流程的高效运行，对各种结构化、非结构化、半结构化和实时（流）格式文件的读写，通过多种协议与其他应用系统实现交互。
            </div>
          </div>
        </div>
      </div>
      <!-- 平台食品技术 -->
      <div class="scientific scientific-other">
        <div class="titls">
          <h1>平台食品技术</h1>
          <h3>Food technology</h3>
        </div>
        <div class="scientific-pto">
          <div class="ptos ptoc">
            <div class="titlet">
              <h1>高保鲜技术</h1>
              <h3>PRESERVATION TECHNOLOGY</h3>
            </div>
            <img class="withbig" :src="bg6Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext line14">
              采用复合保鲜气体，对已装入果蔬的塑料袋或者包装盒内的空气进行置换。从而达到减缓新鲜果蔬的新陈代谢，延长果蔬的保鲜期和货架期。净菜的气调保鲜方式主要是自发调节气体包装（MAP）。国内外常用的保护气体是CO2（二氧化碳）、O2（氧气）、 N2（氮气）三种。<br>
              采收后在流通、贮藏、运输、销售一系列过程中实行低温贮藏，以防止新鲜度和品质下降。冷库和冷链温度必须≤5℃。成本较低、保鲜时间较长。
            </div>
          </div>
          <div class="ptos ptoc" style="margin: 0 24px">
            <div class="titlet">
              <h1>安全溯源</h1>
              <h3>SECURITY TRACEBILITY</h3>
            </div>
            <img class="withbig" :src="bg7Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext line14">
              制定食品安全标准体系，搭建食品安全屏障，打造食品安全品牌城市，形成多产融合的责任、利益、命运共同体。平台构建数字化、信息化、场景化、可视化、全追溯体系，建立“白名单”产品体系，为“八大渠道”消费安全保驾护航，实现食品安全“四个最严”，确保不发生系统性、区域性食品安全风险。
            </div>
          </div>
          <div class="ptos ptoc">
            <div class="titlet">
              <h1>人工智能</h1>
              <h3>ARTIFICIAL INTELLIGENCE</h3>
            </div>
            <img class="withbig" :src="bg8Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext line14">
              智能监控设备接入、WMS系统接入、资源管理与巡检、区块链管理在内的基础设施服务IAAS模块。实时监管任务、物联网设备监控、自动数据校验、风险预警、冷链智能监管等
            </div>
          </div>
          <div class="ptos ptoc" style="margin: 0 24px">
            <div class="titlet">
              <h1>物联网</h1>
              <h3>INTERNET OF THINGS</h3>
            </div>
            <img class="withbig" :src="bg9Src" alt="" />
            <div class="mball"></div>
            <div class="mbballtext line14">
              通过物联网完善基础设施，通过微传感器、微执行器、信号处理和控制电路、通讯接口和电源等部件组成一体化微型器件系统。更加合理的使用分配资源，从而提高了产业效率、效益。
            </div>
          </div>
          <!-- <div class="ptos">
            <img :src="bg15Src" alt="" />
            <div class="titlet">

              <h1>大数据</h1>
              <h3>Big Data</h3>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 产业金融 -->
      <div class="industrial-finance">
        <div class="left-pto"><img :src="bg16Src" alt="" /></div>
        <div class="right-text">
          <div class="title-text">
            <h1>产业金融</h1>
            <h3>INDUSTRIAL FINANCE</h3>
            <div class="text">
              <p>
                巴龙央联万贸以大股东身份投资青岛、济南、烟台、潍坊、长岛5家农商银行，拥有6000亿金融资产。2019年3月26日，青岛农商行在深交所成功上市。巴龙金融与全国农商联盟及国内各大金融机构深度合作，为种植养殖基地、食品加工厂及贸易企业提供供应链金融支持，实现企业可持续发展。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage-introduction">
        <div class="titls">
          <h1>平台合作资源及优势介绍</h1>
          <h3>PLATFORM RESOURCES AND ADVANTAGES</h3>
        </div>
        <div class="advant-bgtitile">
          <div class="zzc"></div>
          <div class="oncemsg">
            <h1>平台合作模式</h1>
            <div><img :src="divideSrc" alt="" /></div>
            <p>
              平台维护原有的合作关系，保护今天的工作岗位
              为用户食品安全保驾护航，为食品供应链降本增效
            </p>
          </div>
          <div class="changecard">
            <el-tabs type="border-card">
              <el-tab-pane label="1">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势一</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    国际冷链物流交易中心项目，是承接国家发展战略的项目。运用产业互联网平台模式，协同政府和企业解决经济运行过程中的痛点、难点、堵点，打通生产、分配、流通、消费各个环节，促进供需平衡、产销平衡、发展平衡，形成企业集群、产业集群、品牌集群、城市集群，，促进消费升级、供给侧结构性改革，助力乡村振兴、城乡融合高质量绿色发展，满足人民对美好生活的向往。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="2">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势二</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    国际冷链物流交易中心项目，是青岛前湾保税港区贯彻落实市委市政府“打造青岛口岸食品、农副产品和进出口贸易枢纽，推动青岛口岸冷链食品进出口贸易发展战略”，通过招商引资引入的新产业、新经济、新业态项目。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="3">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势三</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    国际冷链物流交易中心项目，被国家发改委纳入国家冷链物流枢纽建设中央预算内投资项目。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="4">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势四</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    国际冷链物流交易中心项目，被世界生产力科学院评为“院士项目”。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="5">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势五</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    品牌中国战略规划院、中国营养食品保健协会、中国烹饪协会、中国食品工业协会、中国物流与采购联合会冷链委研究院等为国际冷链物流交易中心项目背书；与山东农业大学深度合作、授牌。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="6">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势六</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    国际冷链物流交易中心项目，得到了山东省、青岛市政府的大力支持。山东省原省长龚正两次作出重要批示，青岛市原市长孟凡利也作了重要批示。2020年省两会期间，姜俊平董事长作了平台经济的发言，得到了省委省政府的高度评价。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="7">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势七</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    国际冷链物流交易中心项目，拥有国内首家关企深度合作港口集成化服务平台——海关查验通关中心。是青岛关区唯一一家多级冷链食品查验中心。提高查验通关效率约75%。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="8">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势八</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    国际冷链物流交易中心项目，拥有目前国内规模最大、装备技术最先进、保税区内食品全品类、全温区、全功能、全追溯、一站式集成化世界生活消费品保税加工中心，总库容约96万吨、148个查验月台。实现食品全产业链的一站式集成化服务，避免后疫情时代的物传人、人传物，严把食品安全关。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="9">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势九</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    以大股东身份投资青岛、济南、烟台、潍坊、长岛5家山东省经济最发达地区的农商银行，拥有6000亿金融资产。2019年3月26日，青岛农商行在深交所成功上市。同时与全国农商联盟、国内大中型金融机构深度合作，为种植养殖基地、食品加工厂及贸易企业提供供应链金融支持。
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="10">
                <div style="width: 960px; margin: 0 auto">
                  <h1 class="goods">优势十</h1>
                  <div class="gang"></div>
                  <p class="textgood">
                    服务优势：打造国内国际双循环示范项目，为海外供应商、国内进口商、食品加工企业提供联合采购、查验通关、保税加工、分拣包装、展示交易、品牌增值、供应链金融、仓运配一站式集成化服务，实现食品“保税、保真、保质、保价、保鲜、保供”。通过预定式订单，实现订单农业、订单加工业、订单服务业。
                  </p>
                </div>
              </el-tab-pane>

              <!-- <el-tab-pane label="消息中心">消息中心</el-tab-pane>
              <el-tab-pane label="角色管理">角色管理</el-tab-pane>
              <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
            </el-tabs>
          </div>
        </div>
      </div>
      <!-- 荣誉 -->
      <div class="honor">
        <div class="titls">
          <h1>荣誉资质</h1>
          <h3>HONOR</h3>
        </div>
        <div class="goodscard">
          <Swipers></Swipers>
          <!-- <el-carousel
            :interval="4000"
            type="card"
            height="318px"
            autoplay="false"
          >
            <el-carousel-item v-for="item in goodsList" :key="item">
              <img style="width:100%;height:100%" :src="item.img" alt="">
            </el-carousel-item>
          </el-carousel> -->
        </div>
      </div>
      <!-- 平台落地進程 -->
      <div class="chinaEcha">
        <div class="titls">
          <h1>平台落地进程</h1>
          <h3>PLATFORM LANDING PROCESS</h3>
        </div>
        <!-- 地图 -->
        <chinaMap @message="conpanymsg"></chinaMap>
        <div class="pingtai">
          <h1>平台分布区域</h1>
          <div class="fbnumber">
            {{ areaNum }}<span style="font-size: 16px; color: #595959">省</span>
          </div>
          <h1>平台落地数量</h1>
          <div class="fbnumber">
            {{ companyNum }}<span style="font-size: 16px; color: #595959">家</span>
          </div>
        </div>
        <!-- 点击展示信息 -->
        <div class="comezs">
          <div class="weapers">
            <div class="container">
              <div class="timeline">
                <div :class="`swiper mySwiper${areamsg.length > 3 ? '' : ' noMore'}`">
                  <div class="swiper-wrapper" ref="swiper">
                    <!-- v-for="(item,index) in areamsg" :key="index" -->
                    <div class="swiper-slide swiper-no-swiping" v-for="(item, index) in areamsg" :key="index + 1">
                      <div class="timeline-text">
                        <h1 style="
                            height: 44px;
                            font-size: 14px;
                            font-weight: 500;
                            color: #434343;
                            line-height: 22px;"
                        >
                          {{ item.platform_name }}
                        </h1>
                        <div style="height: 1px; width: 100%; background: #d9d9d9"></div>
                        <p style="
                            vertical-align: middle;
                            text-align: start;
                            margin: 5px 0;
                            font-size: 14px;
                            color: #595959;">
                          <svg style="width: 20px; height: 20px; vertical-align: middle; margin-right: 5px;"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1024 1024"
                            data-v-394d1fd8=""
                          >
                            <path  fill="currentColor"
                              d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 832a384 384 0 0 0 0-768 384 384 0 0 0 0 768zm-48-247.616L668.608 512 464 375.616v272.768zm10.624-342.656 249.472 166.336a48 48 0 0 1 0 79.872L474.624 718.272A48 48 0 0 1 400 678.336V345.6a48 48 0 0 1 74.624-39.936z"
                            ></path>
                          </svg>
                          <span style="vertical-align: middle">成立时间：{{ item.date }}</span>
                        </p>
                        <p style=" vertical-align: middle; text-align: start; margin: 5px 0; font-size: 14px; color: #595959; display: flex;">
                          <svg style="width: 20px; height: 20px; vertical-align: middle; padding-top: 3px; margin-right: 5px;"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1024 1024"
                            data-v-394d1fd8=""
                          >
                            <path
                              fill="currentColor"
                              d="M288 896h448q32 0 32 32t-32 32H288q-32 0-32-32t32-32z"
                            ></path>
                            <path
                              fill="currentColor"
                              d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z"
                            ></path>
                            <path
                              fill="currentColor"
                              d="M544 384h96a32 32 0 1 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96v-96a32 32 0 0 1 64 0v96z"
                            ></path>
                          </svg>
                          <span class="line-cut2" style="width: calc(100% - 20px); padding-top: 2px;" :title="item.address">{{ item.address ? item.address.trim() : '' }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
            <!-- <div class="containr"></div> -->
          </div>
        </div>
      </div>
      <!-- 新闻动态 -->
      <div class="journalism">
        <div class="titls">
          <h1>新闻动态</h1>
          <h3>NEWS</h3>
          <div class="lookall">
            <span style="vertical-align: middle;" @click="gowordMsg"
              >查看全部
              <img
                style="vertical-align: middle"
                src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon(5).png"
                alt=""
            /></span>
          </div>
        </div>

        <div class="journalismmsg">
          <div class="leftjournalism">
            <a
              class="onceMsg"
              v-for="item in newsList"
              :key="item"
              :href="'https://www.wonmore.com/wordMsg/information?id='+item.id"
              @click.prevent="goMsg(item.id)"
            >
              <!-- <template v-if="index < 20"> -->
              <div class="leftpto"><img :src="item.thumbnail" alt="" /></div>
              <div class="righttext">
                <h1>{{ item.title }}</h1>
                <p>
                  {{ item.introduction }}
                </p>
                <div class="twospan">
                  <span class="time">{{ item.created_at }}</span
                  ><span class="time"
                    ><img src="" alt="" /> 阅读{{ item.views }}</span
                  >
                </div>
              </div>
              <!-- </template> -->
            </a>
          </div>
          <div class="rightjournalism">
            <ul class="list">
              <a
                @click.prevent="goMsg(item.id)"
                class="titlemsg"
                v-for="(item, index) in leftnewsList"
                :key="index"
                :href="'https://www.wonmore.com/wordMsg/information?id='+item.id"
              >
                <h1 class="line-cut1">{{ item.title }}</h1>
                <p>{{ item.created_at }}</p>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div class="endbigpto">
        <div class="bg">
          <div class="item" style="background-image:url(https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/pinpai.png);"></div>
          <div class="item" style="background-image:url(https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/pinpai.png);"></div>
        </div>
        <div class="container">
          <div class="content">合作伙伴</div>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <!-- <button @click="clicks">aa</button> -->
    <!-- <el-carousel
      ref="carousel"
      :height="winHeight"
      direction="vertical"
      indicator-position="none"
      @mousewheel="rollScroll($event)"
      :autoplay="false"
    >
      <el-carousel-item v-for="item in 3" :key="item">
        <h3 class="medium">
          {{ item + 'winHeight--------------'+winHeight }}
        </h3>
      </el-carousel-item>
    </el-carousel> -->
  </div>
</template>
<script>
import { getNewsList, platform, bigEvents } from '@/api/api.js'
import Swipers from './compont/components'
import swiperMsg from './compont/swiperMsg'
import chinaMap from './compont/chinaMap'
import Swiper from 'swiper'
import $ from 'jquery'

import { list, CarouselText, videoIndex } from "./videoTextSwiper"

export default {
  components: {
    Swipers: Swipers,
    chinaMap: chinaMap,
    swiperMsg: swiperMsg,
  },
  data() {
    return {
      videoCanPlay: false,
      threeBigcenter: [
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/leng%20(3).png',
          text: '2020年7月开工，今年年底分期投入运营。总库容约96万吨。建成后，是目前国内规模最大、装备技术最先进、保税区内食品全品类、全温区、全功能、全追溯、一站式集成化世界加工厂，打通日韩及东北亚保税加工业务，填补了国内港口保税加工的历史空白。实现食品全品类保税加工、分拣包装、贴牌灌装一站式集成化服务。经专家测算，可实现产能约2000亿，创造税收约200亿。',
          title: '全品类中央保税食品世界加工中心'
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/leng%20(1).png',
          text: '已投入运营。面积约3万平方米，打造上海进博会的“专业食品馆”和“上合食品城”，形成食品进出口贸易总部基地。为国内外生产商、贸易商提供信息发布、产品展示、交易结算服务，实现现货、期货的线上线下交易、交割、拍卖。',
          title: '国际冷链食品交易中心'
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/leng%20(2).png',
          text: '已投入运营。148个查验食品全品类海关查验月台。是港口经济的重要功能设施，是目前国内首家关企全面深度合作、国内唯一的标杆性示范项目，提高查验通关速度75%以上，带动青岛港冻品进口由2018年的70万吨（货值约140亿元），提升到今年有望实现220万吨（货值约440亿元），进口生活资料占比由8.9%将提高到28%。目前，该中心承担了青岛港海关查验、通关、消杀总量的70%以上，避免后疫情时代物传人、人传物，确保冷链食品安全和品质。',
          title: '海关查验通关中心'
        }
      ],
      companyNum: 0,
      areaNum: 0,
      areamsg: [],
      newsList: [],
      leftnewsList: [],
      isActived: 0,
      winHeight: '',
      goodsList: [
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices.png)',
          value: 0
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(1).png',
          value: 1
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(2).png',
          value: 2
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(3).png',
          value: 3
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(4).png',
          value: 4
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(5).png',
          value: 5
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(6).png',
          value: 6
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(7).png',
          value: 7
        },
        {
          img: 'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/slices/slices(8).png',
          value: 8
        }
      ],
      moveSrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/wommorePlay.mp4',
      jtSrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(17).png',
      bg18Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(18).png',
      bg2Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(2).png',
      bg3Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(3).png',
      bg4Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(4).png',
      bg6Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(6).png',
      bg7Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(7).png',
      bg8Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(8).png',
      bg9Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(9).png',
      bg10Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(10).png',
      bg23Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(23).png',
      bg24Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(24).png',
      bg25Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(25).png',
      bg26Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(26).png',
      bg27Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(27).png',
      bg12Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(12).png',
      bg13Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(13).png',
      bg14Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(14).png',
      bg15Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(15).png',
      bg16Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(16).png',
      bg30Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(30).png',
      bg31Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(31).png',
      bg33Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(33).png',
      bg34Src:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(34).png',
      divideSrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/divide.png',
      bzSrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_tbtx.png',
      zlSrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_zltx.png',
      jgSrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_jytx.png',
      ppSrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_pptx.png',
      jySrc:
        'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_jytx.png',
      // rySrc: require('@/assets/home/icon/ryyyy.png'),
      height: 0,
      flag: true,
      show2: false,
      text1: false,
      pto1: false,
      text2: false,
      pto2: false,
      text3: false,
      pto3: false,
      text4: false,
      pto4: false,
      isStopWatch: false,
      scrollTimer: null,
      timer: null,
      count: 0,
      swiperObj: '',
      isMapHandle: false
    }
  },
  computed:{
    videoList(){
      return list.value
    },
    videoIndex(){
      return videoIndex.value
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.getScroll)
    this.winHeight = document.body.clientHeight
    window.pageYOffset = 0
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    this.$nextTick(() => {
      this.myswiper()
      this.myswiper1()
    })
    this.getNewsList()
    this.getNewsList1()
    this.getmapmsg()
    this.bigEvents()

    let that = this;
    document.onclick = function (e) {
      if (that.$route.path == '/') {
        if (e.target.className == 'mySwiper' || $(e.target).hasClass('mySwiper') || $(e.target).parents('.mySwiper').length > 0) {
          that.isMapHandle = true
        }

        if (!that.isMapHandle) {
          that.areamsg = []
          that.$nextTick(() => {
            if (that.swiperObj) that.swiperObj.update()
            that.isMapHandle = false
          })
        } else {
          that.isMapHandle = false
        }
      }
    },
    this.scrollInit()
    // console.log(document.body.clientHeight,'document.body.clientHeight')


    const carouselText = new CarouselText();
    carouselText.startCarouselText();
  },
  // watch()
  methods: {
    scrollInit() {
			const scrollDiv = document.getElementById("homepage");
			var that = this;
			var isFirefox=/Firefox/i.test (navigator.userAgent) ; //判断是不是火狐浏览器
			var mwEvent = !isFirefox ? 'mousewheel' : 'DOMMouseScroll'
			scrollDiv.addEventListener(mwEvent, function (e) {
        that.rollScroll(e, mwEvent);
      }, false);
    },
    gowordMsg(){
      this.$router.push({
        name:'platform'
      })
    },
    bigEvents() {
      // bigEvents().then((res) => {
      // console.log(res, 'ressssss12456489798')
      // })
    },
    prev() {
      //  this.$refs.swiper.$swiper.slidePrev();
      //  console.log( this.$refs.swiper.$swiper,'this.swiper')
      // this.swiper.slidePrev();
    },
    next() {
      //  this.$refs.swiper.$swiper.slideNext();
    },
    conpanymsg(res) {
      let that = this
      this.areamsg = res
      this.isMapHandle = true
      this.$nextTick(() => {
        if (this.swiperObj) {
          this.swiperObj.update()
          that.swiperObj.slideTo(0, 1)
          setTimeout(() => {
            document.getElementsByClassName("swiper-wrapper")[0].style.transform = "translate3d(0px, 0px, 0px)"
          },50)
        }
      })
    },
    goMsg(value) {
      this.$router.push({
        name: 'information',
        query: { id: value }
      })
    },
    myswiper1: function () {
      var swiper = new Swiper('.mySwiper', {
        initialSlide: 0,
        slidesPerView: 3.3,
        spaceBetween: 30,
        onlyExternal: true,
        noSwiping : true,
        scrollbar: '.swiper-scrollbar',
        scrollbarHide: false,
        observer: true,
        observeParents: true,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next'
      })
      this.swiperObj = swiper
      var appendNumber = 4
      var prependNumber = 1
    },
    getmapmsg() {
      let that = this
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/platform',
        dataType: 'json',
        type: 'get',
        async: false,
        data: {},
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          if (res.status === 'success') {
            that.areaNum = Object.keys(res.data.platform).length
            let number = 0
            let arr = []
            for (let key in res.data.platform) {
              number = number + res.data.platform[key].length
              arr.push({ name: res.data.platform[key], value: Number(key) })
            }
            // this.areamsg = arr
            that.companyNum = number
          } else {
          }
        }
      })
      /*platform().then((res) => {
        if (res.status === 'success') {
          this.areaNum = Object.keys(res.data.region).length
          let number = 0
          let arr = []
          for (let key in res.data.platform) {
            number = number + res.data.platform[key].length
            arr.push({ name: res.data.platform[key], value: Number(key) })
          }
          // this.areamsg = arr
          this.companyNum = number
        }
      })*/
    },
    getNewsList() {
      // let data = { cat_id: 0, page: 1, page_size: 9 }
      /*getNewsList({ data }).then((res) => {
        if (res.status === 'success') {
          this.newsList = res.data.data.filter((item, index) => {
            return index < 3
            console.log(this.newsList, 'this.newsList')
          })
          this.leftnewsList = res.data.data.filter((item, index) => {
            return index >= 3
          })
          console.log(this.leftnewsList, 'this.leftnewsList')
        } else {
        }
      })*/
      let that = this
      let data = new FormData();
      data.append('cat_id',1)
      data.append('page',1)
      data.append('page_size',9)
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/news_list',
        dataType: 'json',
        type: 'post',
        async: false,
        data: data,
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          if (res.status === 'success') {
            that.newsList = res.data.data.filter((item, index) => {
              return index < 3
            })
          } else {
          }
        }
      })
    },
    getNewsList1(){
      let that = this
      let data = new FormData();
      data.append('cat_id',2)
      data.append('page',1)
      data.append('page_size',9)
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/news_list',
        dataType: 'json',
        type: 'post',
        async: false,
        data: data,
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          if (res.status === 'success') {
            that.leftnewsList = res.data.data.filter((item, index) => {
              return index < 6
            })
          } else {
          }
        }
      })
    },
    myswiper(value, num) {
      var timelineSwiper = new Swiper('.timeline .swiper-container', {
        direction: 'vertical',
        loop: false,
        speed: 1600,
        mousewheelControl: true,
        pagination: '.swiper-pagination',
        paginationBulletRender: function (swiper, index, className) {
          // var year = document.querySelectorAll('.swiper-slide')[index].getAttribute('data-year');
          // return '<span class="' + className + '">' + year + '</span>';
        },
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev'
      })
    },
    clicks() {
      console.log(this, 'wobeidianjile')
    },


    canplay(e){
      if(e.isTrusted){
        this.videoCanPlay = true;
      }
    },
    rollScroll(e, _type) {
      let anum = this.getScroll().top;
      var delta = (_type == 'mousewheel') ? (e.wheelDelta || -e.deltaY) : -e.detail

      if (this.timer) {
        clearTimeout(this.timer);
      }
      // if($('.ylvideo')[0].classList[1]==='active'){
      //   if(this.count<1){
      //     this.timer = setTimeout(() => {
      //       this.count++;
      //       // window.scrollTo(0,0);
      //     }, 200)
      //   }
      // } else {
      //   this.count = 0;
      // }

      const carouselText = new CarouselText();
      // 向下滑动
      if (delta < 0 ) {
        if(this.isStopWatch) return false;
        this.isActived = 1;
        carouselText.finishCarouselText();
        setTimeout(() => {
          this.$store.commit("SET_OVERFLOW_HIDDEN", false);
        },1000)
      }
      // 向上滑动
      if (delta > 0 && anum < 100) {
        this.isActived = 2;
        this.isStopWatch = true;
        carouselText.startCarouselText();
        if(this.scrollTimer) clearTimeout(this.scrollTimer)
        this.scrollTimer = setTimeout(()=>{
          this.$store.commit("SET_OVERFLOW_HIDDEN", true);
        },1000)
        setTimeout(() => {
          this.isStopWatch = false;
        }, 1000)
      }

      if (delta < 0) {
        this.pto1 = true
        setTimeout(() => {
          this.text1 = true
        }, 800)
      } else if (delta > 0 && anum == 0) {
        this.text1 = false
        this.text2 = false
        this.text3 = false
        this.pto1 = false
        this.pto2 = false
        this.pto3 = false
      }
      if (anum > 160) {
        this.pto2 = true
        this.text2 = true
        setTimeout(() => {
          this.text4 = true
        }, 2000)
      }
      if (anum > 600) {
        this.pto3 = true
        this.text3 = true
        setTimeout(() => {
          this.pto4 = true
        }, 2000)
      }
      // let _that = this
      // console.log(this.getScroll(), " _that _that _that");
      // if (this.getScroll().top > 0) {
      //   this.$nextTick(() => {
      //     // document.documentElement.scrollTop =1000
      //     // this.$refs.carousel.scrollTop = 1000;
      //     console.log(document.documentElement.scrollTop)
      //   });
      // }
      // setInterval(() => {
      //   if (this.height > -980 && this.flag == true) {
      //     this.height = this.height - 100
      //     if (this.height < -980) {
      //       this.flag = false
      //     }
      //   }
      //   console.log(this.height,this.flag,'this.height')
      //   // if (this.height < 0 && this.flag == false) {
      //   //   this.height = this.height + 100
      //   //   if (this.height > 0) {
      //   //     this.flag = true
      //   //   }
      //   // }
      // }, 10)
      // chrome、ie使用的wheelDelta，火狐使用detail
      // let scrollVal = event.wheelDelta || event.detail
      // 节流
      // if (!_that.timeOut) {
      //   _that.timeOut = setTimeout(() => {
      //     _that.timeOut = null
      //     scrollVal > 0
      //       ? _that.$refs.carousel.prev()
      //       : _that.$refs.carousel.next()
      //   }, 300)
      // } else {
      // }
    },

    getScroll() {
      return {
        left:
          window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft ||
          0,
        top:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0
      }
      // let scrolltop = window.pageYOffset || document.body.scrollTop || 0
      // if (scrolltop == 700) {
      //   this.show2 = true
      // }
      console.log(scrolltop, 'scrolltopscrolltop')
    }
  }
}
</script>

<style lang="scss" scoped>
.mySwiper.noMore{
  .swiper-wrapper{
    justify-content: center;
  }
  .swiper-button-prev,
  .swiper-button-next{display: none;}
}
.weapers {
  padding: 0;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1) !important;
    // background-color: #fff;
    flex-direction: column;
    .timeline {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .swiper {
        width: 1000px;
        // margin: 0 320px;
        // width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        // width: 282px !important;
        height: 150px;

        .timeline-text {
          width: 100%;
          height: 100%;
          overflow: hidden;
          margin: 8px;
        }
      }
      .swiper-wrapper {
        // display: flex;
        // justify-content: center;
        // margin: 0 50px;
      }
      .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
        pointer-events: unset;
      }
      .swiper-button-next {
        z-index: 99999;
        height: 50px;
        width: 50px;
        right: calc((100% - 1000px) / 2 - 80px);
      }
      .swiper-button-prev {
        height: 50px;
        z-index: 99999;
        width: 50px;
        left: calc((100% - 1000px) / 2 - 80px);
      }
      .swiper-scrollbar {
        width: 1000px;
        height: 3px;
        margin-top: 10px;
      }
      .swiper-scrollbar-drag {
        height: 10px;
        // margin: 20px auto;
        // width: 100%;
        width: 100px;
        bottom: 85px !important;
      }
    }
  }
}
.active {
  animation: mymove 1s 1;
  animation-fill-mode: forwards;
}
.noActive {
  animation: nomymove 1s 1;
  animation-fill-mode: forwards;
}
@keyframes mymove {
  0% {
    top: 0px;
    pointer-events: none;
  }
  100% {
    top: -100vh;
    pointer-events: none;
  }
}
@keyframes nomymove {
  0% {
    top: -100vh;
    pointer-events: none;
  }
  100% {
    top: 0px;
    pointer-events: none;
  }
}
.gang {
  width: 80px;
  height: 2px;
  background: #c5a074;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 12px;
  margin-bottom: 6px;
}
.titls {
  text-align: center;
  text-transform: uppercase;
  margin-top: 80px;
  margin-bottom: 40px;
  h1 {
    height: 54px;
    font-size: 36px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    line-height: 54px;
  }
  h3 {
    height: 24px;
    font-size: 20px;
    font-family: Noto Serif-Regular, Noto Serif;
    font-weight: 400;
    color: #bfbfbf;
    line-height: 24px;
  }
}
.homepage {
  position: relative;
  overflow: hidden;
  width: 100%;
  .ylvideo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    video{
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .video-mask{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .6);
      z-index: 9999;
      p{
        position: absolute;
        top: 50%;
        margin-top: -20px;
        left: 50%;
        margin-left: -500px;
        width: 1000px;
        color: #fff;
        font-size: 50px;
        text-align: center;
      }
    }
  }
}
.downDom {
  overflow: hidden;
  margin-top: 96px;
}
.introduce {
  margin-bottom: 140px;
}
.transition-box {
  position: relative;

  // margin-bottom: 10px;
  width: 100%;
  img {
    width: 100%;
    object-fit: fill;
  }
  .flotTop {
    position: absolute;
    top: 130px;
    left: 50%;
    margin-left: -300px;
    text-align: center;
    .title {
      // width: 144px;
      height: 54px;
      font-size: 36px;
      text-align: center;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 54px;
    }
    .etitle {
      // width: 227px;
      height: 24px;
      font-size: 20px;
      font-family: Noto Serif-Regular, Noto Serif;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      text-transform: uppercase;
    }
    .msg {
      margin-top: 40px;
      width: 600px;
      height: 96px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
  }
  // height: 100px;
  // border-radius: 4px;
  // background-color: #409EFF;
  // text-align: center;
  // color: #fff;
  // padding: 40px 20px;
  // box-sizing: border-box;
  // margin-right: 20px;
  // background: url('~@/assets/home/bg(17).png');
}
.advantage {
  width: 100%;
  // margin: 140px 0;
  .fixtwobox {
    display: flex;
    margin-bottom: 120px;
    .pto {
      flex: 1;
      .bigpto {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      flex: 1;
      padding: 0 60px;
      .bgcE {
        height: 105px;
        font-size: 48px;
        font-family: Noto Serif SC-SemiBold, Noto Serif SC;
        font-weight: 500;
        color: #434343;
        line-height: 115px;
        position: relative;
        .eb {
          position: absolute;
          top: 0;
          left: -18px;
        }
      }
      p {
        width: 520px;
        height: 180px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #434343;
        line-height: 30px;
      }
    }
    .text1 {
      // text-align: end;
      display: flex;
      justify-content: flex-end;
      .bgcE {
        height: 105px;
        font-size: 48px;
        font-family: Noto Serif SC-SemiBold, Noto Serif SC;
        font-weight: 500;
        color: #434343;
        line-height: 115px;
        background-position: -11px;
      }
      p {
        width: 100%;
        // text-align: end;
        span {
          width: 520px;
          display: inline-block;
        }
        // display: flex;
        // justify-content: end;
      }
    }
  }
}
.line6{
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.line14{
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 14;
}
.swiper-card {
  // height: 710px;
  background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg.png') no-repeat;
  background-size: cover;
  padding-bottom: 40px;
  overflow: hidden;
  ::v-deep .el-icon {
    font-size: 36px;
  }
  .medium {
    height: 100%;
    // width: 996px !important;
    position: relative;
    .medium-img {
      width: 100%;
      // height: 100%;
    }
    .medium-text {
      position: absolute;
      bottom: 0;
      padding: 24px 0;
      display: flex;
      background: rgba(0, 0, 0, 0.5) center center;
      .title {
        flex: 1.4;
        height: 60px;
        font-size: 20px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        border-right: 1px solid #fff;
        margin: 0 auto;
        // text-align: center;
        padding: 0 40px;
      }
      .text {
        flex: 7;
        height: 132px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        padding: 0 40px;
      }

      // height: 180px;
    }
  }
  .swper_title {
    h1 {
      height: 54px;
      font-size: 36px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 54px;
    }
    h3 {
      height: 24px;
      font-size: 20px;
      font-family: Noto Serif-Regular, Noto Serif;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
    // display: block;
    color: #fff;
    overflow: hidden;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;
    // width: 485px;
    height: 78px;
    // border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  // padding-top: ;
  .el-carousel {
    overflow: hidden;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  .el-carousel__item {
    width: 996px;
    width: 50vw;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
.tree-platform {
  overflow: hidden;
  height: 600px;
  background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(1).png') no-repeat;
  background-size: 100%;
  .tree-title {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 60px;
    h1 {
      height: 54px;
      font-size: 36px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 54px;
    }
    h3 {
      height: 24px;
      font-size: 20px;
      font-family: Noto Serif-Regular, Noto Serif;
      font-weight: 400;
      color: #bfbfbf;
      line-height: 24px;
    }
  }
  .tree-pto {
    display: flex;
    justify-content: center;
    .pro_text {
      height: 302px;
      box-shadow: 0 9px 28px 8px rgba(24, 39, 116, 0.05), 0 6px 16px 1px rgba(24, 39, 116, 0.05), 0 3px 6px -4px rgba(24, 39, 116, 0.05);
      position: relative;
      overflow: hidden;
      transition: all .5s;
      .titles {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #fff;
        width: 100%;
        height: 94px;
        padding-top: 17px;
        padding-left: 24px;
        h1 {
          height: 36px;
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #262626;
          line-height: 36px;
        }
        h3 {
          height: 24px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #bfbfbf;
          line-height: 24px;
        }
      }
    }
    .pro_text:hover {
      cursor: pointer;
      /*放大倍数*/
      transform: scale(1.1);
    }
  }
}
.four-service {
  .four-pto {
    .col1 {
      display: flex;
      justify-content: center;

      .pto-text:hover {
        cursor: pointer;
        z-index: 9999;
        /*放大倍数*/
        background-size: 105%;
      }

      .pto-text {
        .zzc {
          z-index: 1;
          width: 100%;
          height: 100%;
          position: absolute;
          background-image: linear-gradient(
            to right,
            rgb(0, 0, 0),
            transparent
          );
          background-position: center;
          background-blend-mode: normal;
          // background:  rgba(0,0,0,.2);
          // background-blend-mode: multiply;
          filter: blur(3px);
        }
        width: 600px;
        overflow: hidden;

        transition: all 1s;
        position: relative;
        img {
          position: absolute;
          right: 0;
        }
        position: relative;
        height: 200px;
        .text {
          position: absolute;
          top: 70px;
          left: 32px;
          z-index: 9999;
          h1 {
            height: 36px;
            font-size: 24px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 36px;
          }
          h3 {
            height: 24px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
          }
        }
      }
      .pto-text1 {
        background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(19).png')
          no-repeat;
        background-size: 100%;
      }
      .pto-text2 {
        background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(20).png')
          no-repeat;
        background-size: 100%;
      }
      .pto-text3 {
        background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(21).png')
          no-repeat;
        background-size: 100%;
      }
      .pto-text4 {
        background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(22).png')
          no-repeat;
        background-size: 100%;
      }
    }
  }
}
.five-system {
  .five-pto {
    display: flex;
    justify-content: center;
    .ptos:hover {
      cursor: pointer;
      width: 282px;
      .withbig {
        width: 282px;
        height: 402px;
      }
      .mball {
        width: 282px;
        height: 402px;
      }
      .mbballtext {
        width: 282px;
        height: 422px;
      }
    }
    .ptos {
      height: 402px;
      width: 221px;
      transition: all 1s;
      position: relative;
      overflow: hidden;
      .withbig {
        transition: all 1s;
        width: 221px;
        height: 402px;
      }
      .titlet {
        bottom: 30px;
        left: 24px;
        position: absolute;
        img {
          width: 60px;
          height: 60px;
        }
        h1 {
          height: 42px;
          font-size: 28px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 42px;
        }
        h3 {
          height: 24px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
      }
      .mbballtext {
        padding: 24px;
        width: 212px;
        height: 0px;
        transition: all 1s;
        color: #000;
        position: absolute;
        bottom: -20px;
        z-index: 9999;
      }
      .mball {
        width: 212px;
        height: 0px;
        transition: all 1s;
        z-index: 999;
        position: absolute;
        bottom: 0;
        background: hsla(0, 0%, 100%, 0.6);
        // box-shadow: 10px 10px 5px #888888;
        // background-blend-mode: multiply;
        backdrop-filter: blur(5px);
      }
    }
  }
}
.scientific {
  height: 680px;
  overflow: hidden;
  background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(11).png')
    no-repeat;
  .scientific-pto {
    display: flex;
    justify-content: center;
    .ptos:hover {
      cursor: pointer;
      width: 282px;
      .withbig {
        width: 282px;
        height: 348px;
      }
      .mball {
        width: 282px;
        width: 100%;
        height: 348px;
      }
      .mbballtext {
        width: 282px;
        width: 100%;
        height: 368px;
      }
    }
    .ptoc:hover {
      cursor: pointer;
      width: 322px;
      .withbig {
        width: 322px;
        height: 348px;
      }
      .mball {
        width: 322px;
        width: 100%;
        height: 348px;
      }
      .mbballtext {
        width: 322px;
        width: 100%;
        height: 368px;
      }
    }
    .ptos {
      overflow: hidden;
      width: 221px;
      height: 480px;
      position: relative;
      transition: all 1s;
      .withbig {
        height: 348px;
        transition: all 1s;
        width: 221px;
      }

      .titlet {
        padding-top: 24px;
        padding-left: 24px;
        width: 100%;
        // top: 0px;
        // left: 0px;
        height: 130px;
        // position: absolute;
        background-color: #fff;
        h1 {
          height: 42px;
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #262626;
          line-height: 42px;
        }
        h3 {
          height: 24px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #bfbfbf;
          line-height: 24px;
        }
      }
      .mbballtext {
        padding: 24px;
        width: 212px;
        width: 100%;
        height: 0px;
        transition: all 1s;
        color: #000;
        position: absolute;
        bottom: -20px;
        z-index: 9999;
      }
      .mball {
        width: 100%;
        height: 0px;
        transition: all 1s;
        z-index: 999;
        position: absolute;
        bottom: 2px;

        background: hsla(0, 0%, 100%, 0.6);
        // box-shadow: 10px 10px 5px #888888;
        // background-blend-mode: multiply;
        backdrop-filter: blur(5px);
        overflow: hidden;
      }
    }
    .ptoc {
      width: 282px;
      .withbig {
        width: 282px;
      }
    }
  }
}
.scientific-other {
  margin-top: -2px;
  height: 758px;
  background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(5).png')
    no-repeat;
}
.industrial-finance {
  display: flex;
  justify-content: center;
  height: 410px;
  .left-pto {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-text {
    width: 50%;
    background-color: rgba(197, 138, 68, 1);
    padding-top: 98px;
    padding-left: 50px;
    .title-text {
      h1 {
        height: 54px;
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 54px;
      }
      h3 {
        height: 24px;
        font-size: 20px;
        font-family: Noto Serif-Regular, Noto Serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
      .text {
        margin-top: 24px;
        height: 72px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        p {
          width: 500px;
        }
      }
    }
  }
}
.advant-bgtitile {
  position: relative;
  width: 1200px;
  height: 532px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  .zzc {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(10).png')
      rgba(0, 0, 0, 0.5) no-repeat center center;
    background-blend-mode: multiply;
    // filter: blur(7px);
  }
  .oncemsg {
    // opacity: 1;
    cursor: pointer;
    h1 {
      height: 36px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 36px;
      margin-top: 40px;
      margin-bottom: 16px;
    }
    p {
      margin: 16px auto 32px;
      width: 528px;
      height: 72px;
      font-size: 24px;
      font-family: Noto Serif SC-SemiBold, Noto Serif SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 36px;
    }
  }
  .changecard {
    text-align: start;

    // width: 960px;
    height: 182px;
    margin: 34px auto;

    .goods {
      height: 36px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 36px;
    }
    .textgood {
      height: 121px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
    }
  }
}



.honor {
  overflow: hidden;
  background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(28).png') no-repeat;
  background-size: 100% 100%;
  padding-bottom: 100px;
  margin-top: 50px;
  .goodscard {
    max-width: 1200px;
    padding: 0 50px;
    margin: 0 auto;

    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }
}
.chinaEcha {
  overflow: hidden;
  position: relative;
  background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/mapbg.png')
    no-repeat;
  background-size: 100% 100%;
  height: 680px;
  .pingtai {
    position: absolute;
    top: 200px; left: calc(50% + 350px);
    width: 120px;
    height: 192px;
    .fbnumber {
      height: 72px;
      font-size: 36px;
      font-family: DINPro-Bold, DINPro;
      font-weight: bold;
      color: #c5a074;
      line-height: 72px;
      span{margin-left: 3px;}
    }
    h1 {
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 24px;
    }
  }
  .comezs {
    position: absolute;
    bottom: 20px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    .swiper-container {
      height: 507px;
      width: 100%;
      overflow: hidden;
    }
    .swiper-wrapper {
      // transition: 1s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.1s;
    }
    .swiper-slide {
      color: #333;
      overflow: hidden;
      display: flex;
      justify-content: center;
      .timeline-year {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #c5a074;
        line-height: 24px;
        padding-bottom: 20px;
      }
      .timeline-title {
        font-size: 48px;
        font-family: Noto Serif SC-SemiBold, Noto Serif SC;
        font-weight: 500;
        color: #434343;
        line-height: 72px;
        padding-bottom: 20px;
      }
      .timeline-text {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #434343;
        line-height: 24px;
      }
    }
    .swiper-slide-content {
      text-align: center;
      max-width: 1400px;
      font-size: 12px;
      z-index: 2;
      display: flex;
      align-items: center;
      .content {
        width: 528px;
        text-align: left;
        margin-right: 72px;
      }
    }
  }
}
.journalism {
  overflow: hidden;
  margin-bottom: 80px;
  .lookall {
    cursor: pointer;
    text-align: center;
    margin-top: 16px;
    height: 24px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 500;
    color: #c5a074;
    line-height: 24px;
  }
  .journalismmsg {
    display: flex;
    justify-content: center;
    height: 485px;
    overflow: hidden;
    padding: 1px;
    .leftjournalism {
      width: 690px;
      .onceMsg {
        height: 120px;
        cursor: pointer;
        box-shadow: -2px -2px 15px #ebebeb;
        //width: 690px;
        padding: 16px 16px 8px 16px;
        display: flex;
        margin-bottom: 24px;
        .leftpto {
          flex: 2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .righttext {
          cursor: pointer;
          padding-left: 16px;

          h1 {
            height: 24px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #434343;
            line-height: 24px;
          }
          p {
            height: 68px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #8c8c8c;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:3;
          }
          .twospan {
            .time {
              height: 22px;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #bfbfbf;
              line-height: 22px;
            }
          }
          flex: 8;
        }
      }
    }
    .rightjournalism {
      margin-left: 24px;
      box-shadow: -2px -2px 15px #ebebeb;
      width: 486px;
      height: 480px;
      overflow: hidden;
      .list {
        height: 480px;
        padding: 0 12px 0 24px;
        .titlemsg {
          display: block;
          cursor: pointer;
          padding: 15px 0;
          &:not(:last-child){border-bottom: 1px solid #f3f3f3;}
          h1 {
            height: 24px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #434343;
            line-height: 24px;
            margin-bottom: 3px;
          }
          p {
            height: 22px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 22px;
          }
        }
      }
    }
  }
}
.endbigpto {
  height: 36vh;
  position: relative;
  overflow: hidden;
  perspective: 230px;
  -webkit-perspective: 230px;
  .bg{
    height: calc(190vw / 16 * 9 * 1.4);
    position: absolute;
    left: -45%;
    right: -45%;
    top: -16%;
    bottom: -27%;
    transform: rotateX(10deg);
    .item{
      padding-top: calc(190vw / 16 * 9);
      background-repeat: no-repeat;
      background-size: 100% auto;
      animation:endbigpto1 25s linear infinite;
    }
  }
  .container{
    background: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .content{
      color: #fff;
      font-size: 46px;
    }
  }
}
@keyframes endbigpto1 {
  from {transform: translateY(0);}
  to {transform: translateY(calc((190vw / 16 * 9) * -1));}
}
@-webkit-keyframe endbigpto1 {
  from {transform: translateY(0);}
  to {transform: translateY(calc((190vw / 16 * 9) * -1));}
}
</style>
<style scoped>
/* /deep/     .el-car-item{
      width: 428px !important;
    } */
/deep/ .el-tabs--border-card {
  background: none;
  border: none;
  box-shadow: unset;
}
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: rgba(197, 160, 116, 1) !important;
  color: #fff;
}
/deep/ .el-tabs__nav{
  width: 100%;
}
/deep/ .el-tabs__item {
  font-size: 24px;
  line-height: 50px;
  width: 120px;
  height: 50px;
  padding: 0;
  text-align: center;
  opacity: 1;
  color: rgb(197, 160, 116);
}
/deep/ .el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child{
  padding-right: 0;
}
/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item+.el-tabs__item,
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child{
  margin-left: 0;
}
/deep/ .el-carousel__arrow--right {
  /* right: 455px !important; */
}
/deep/ .el-carousel__arrow--right {
  text-align: center;
  line-height: 7px;
  background: #444444;
  width: 56px;
  height: 56px;
  /* font-size: 36px !important; */
  color: #fff;
  /* /deep/ .el-icon {
    font-size: 36px !important;
  } */
}
/deep/ .el-carousel__arrow--left {
  /* left: 500px !important; */
}
/deep/ .el-carousel__arrow--left {
  text-align: center;
  line-height: 7px;
  background: #444444;
  width: 56px;
  height: 56px;
  /* font-size: 36px !important; */
  color: #fff;
  /* /deep/ .el-icon {
    font-size: 36px !important;
  } */
}
/deep/ .el-carousel__indicator--horizontal {
  width: 74px !important;
}
/deep/ .el-carousel__button {
  width: 74px !important;
}
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #c5a074;
}
/deep/ .el-tabs__item:nth-child(2) {
  padding: 0 !important;
}
/deep/ .changecard .el-tabs__nav-wrap{overflow: unset;}
/deep/ .changecard .el-tabs__nav-scroll{overflow: unset;}
/deep/ .changecard .el-tabs__nav-scroll::after{
  content: "";
  display: table;
  clear: both;
}
/deep/ .changecard .el-tabs__item.is-top::after{
  content: "";
  width: 0;
  position: absolute;
  top: 100%; left: calc(50% - 10px);
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 0;
  transition: all .6s;
  display: block;
}
/deep/ .changecard .el-tabs__item.is-top.is-active::after{
  border-top: 10px solid rgba(197, 160, 116, 1) !important;
}
</style>
<style lang="scss">
.line-cut1{
  white-space: nowrap !important;
  text-overflow:ellipsis !important;
  overflow: hidden !important;
}
.line-cut2{
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

</style>
