<template>

</template>

<script>


export default {
  name: 'serviceCenter',
  props: ['areamsg'],
  data() {
    return {
      companyList: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      
    })
  },

  computed: {},
  methods: {
    getCompany() {},
    prev() {
      //  this.$refs.swiper.$swiper.slidePrev();
      //  console.log( this.$refs.swiper.$swiper,'this.swiper')
      // this.swiper.slidePrev();
    },
    next() {
      //  this.$refs.swiper.$swiper.slideNext();
    },

  }
}
</script>

<style lang="scss" scoped>
.weapers {
  padding: 108px 0;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1) !important;
    // background-color: #fff;
    flex-direction: column;
    .timeline {
      width: 100%;
      // background-color: #fff;
      display: flex;
      justify-content: center;
      .swiper {
        width: 1000px;
        // margin: 0 320px;
        // width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        width: 282px !important;
        height: 150px;

        .timeline-text {
          width: 100%;
          height: 100%;
          overflow: hidden;
          padding: 12px;
        }
      }
      .swiper-wrapper {
        display: flex;
        justify-content: center;
        // width: 880px;
        margin: 0 50px;

        // overflow: hidden;
      }
      .swiper-button-next {
        z-index: 99999;
        height: 50px;
        width: 50px;
        right: 370px;
      }
      .swiper-button-prev {
        height: 50px;
        z-index: 99999;
        width: 50px;
        left: 370px;
      }
      .swiper-scrollbar {
        height: 10px;
        // margin: 20px auto;
        // width: 100%;
        width: 1000px;
        left: 50%;
        margin-left: -500px;
        // margin: 0 auto;
        bottom: 85px !important;
      }
      .swiper-scrollbar-drag {
        height: 10px;
        // margin: 20px auto;
        // width: 100%;
        width: 100px;
        bottom: 85px !important;
      }
    }
  }
}
</style>
