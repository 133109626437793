import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import axios from "axios";
// import echarts from "echarts";
// const app = createApp(App);
// app.config.globalProperties.$http = axios;
// createApp.prototype.$echarts = echarts;
import 'element-plus/dist/index.css'
import './style/animate.min.css'
import 'swiper/dist/css/swiper.css';
import './common/font.css'
import 'font-awesome/scss/font-awesome.scss'
// axios.defaults.baseURL="https://outcommon.wonmore.com/";
createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
document.dispatchEvent(new Event('render-event')) // 预渲染

